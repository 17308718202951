import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type UserFragmentFragment = { __typename?: 'User', id: number, email: string, name: string, imageUrl?: string | null, pipedriveId?: string | null, salesforceId?: string | null, jobTitle?: string | null, podId?: number | null, active: boolean, isRobot: boolean, phone?: string | null, birthday?: string | null, comment?: string | null, deputyUserId?: number | null, rfqLimit?: number | null, region?: Types.UserRegionEnum | null, roles: Array<{ __typename?: 'Role', id: number, name: string }>, autoAssignmentSettings?: { __typename?: 'AutoAssignmentSettings', customerOrderGrade?: Array<Types.CustomerOrderGradeEnum> | null, languages?: Array<Types.LanguageEnum> | null, maxDeals?: number | null, overloadPossible?: boolean | null, technology?: Array<number> | null } | null, deputyUser?: { __typename?: 'User', id: number, name: string, email: string, imageUrl?: string | null } | null, substitutedBy?: Array<{ __typename?: 'OmsSubstitution', createdAt: string, endDate: string, id: number, startDate: string, deputyUser: { __typename?: 'User', name: string, id: number }, user: { __typename?: 'User', name: string, id: number } }> | null, substitutedByArchived?: Array<{ __typename?: 'OmsSubstitution', createdAt: string, endDate: string, id: number, startDate: string, deputyUser: { __typename?: 'User', name: string, id: number }, user: { __typename?: 'User', name: string, id: number } }> | null, substitutingFor?: Array<{ __typename?: 'OmsSubstitution', createdAt: string, endDate: string, id: number, startDate: string, deputyUser: { __typename?: 'User', name: string, id: number }, user: { __typename?: 'User', name: string, id: number } }> | null, substitutingForArchived?: Array<{ __typename?: 'OmsSubstitution', createdAt: string, endDate: string, id: number, startDate: string, deputyUser: { __typename?: 'User', name: string, id: number }, user: { __typename?: 'User', name: string, id: number } }> | null };

export const UserFragmentFragmentDoc = gql`
    fragment userFragment on User {
  id
  email
  name
  imageUrl
  pipedriveId
  salesforceId
  jobTitle
  podId
  active
  isRobot
  phone
  birthday
  comment
  deputyUserId
  rfqLimit
  region
  roles {
    id
    name
  }
  autoAssignmentSettings {
    customerOrderGrade
    languages
    maxDeals
    overloadPossible
    technology
  }
  deputyUser {
    id
    name
    email
    imageUrl
  }
  substitutedBy {
    createdAt
    deputyUser {
      name
      id
    }
    user {
      name
      id
    }
    endDate
    id
    startDate
  }
  substitutedByArchived {
    createdAt
    deputyUser {
      name
      id
    }
    user {
      name
      id
    }
    endDate
    id
    startDate
  }
  substitutingFor {
    createdAt
    deputyUser {
      name
      id
    }
    user {
      name
      id
    }
    endDate
    id
    startDate
  }
  substitutingForArchived {
    createdAt
    deputyUser {
      name
      id
    }
    user {
      name
      id
    }
    endDate
    id
    startDate
  }
}
    `;