import { CurrencyEnum } from '__generated__/types';
import { RegionData, RegionLocales, Regions } from 'config/regions/types';
import { ENV_APP_HOST_INDIA, ENV_CUSTOMERS_APP_HOST_INDIA, ENV_PARTNERS_APP_HOST_INDIA } from 'constants/env';

import { ReactComponent as Icon } from './assets/india.svg';

export const INDIARegionConfig: RegionData<Regions.INDIA> = {
  region: Regions.INDIA,
  orgName: 'Xometry India',
  name: 'India',
  shortName: 'INDIA',
  locale: RegionLocales.EN,
  currency: CurrencyEnum.Eur,
  icon: Icon,
  host: ENV_APP_HOST_INDIA,
  customersHost: ENV_CUSTOMERS_APP_HOST_INDIA,
  partnersHost: ENV_PARTNERS_APP_HOST_INDIA,
  headerColor: '#072F4F',
};
