import { Part } from 'interfaces';
import { IDealLot } from 'interfaces/stores/Shipping';

export const mapCustomerObjectToLot = (part: Part): IDealLot => {
  // Provider and customer price are the same for customer objects
  const price = part.customerObjectPrice ? Number(part.customerObjectPrice.amount) : 0;
  const priceMoney = part.customerObjectPrice || undefined;

  return {
    id: null,
    customerPrice: price,
    customerPriceMoney: priceMoney,
    providerPrice: price,
    providerPriceMoney: priceMoney,
    files: part.attachmentFiles,
    isPlaced: false,
    isUsed: false,
    parentId: null,
    partHsCodeConfirmedAt: part.hsCodeConfirmedAt as Date | null,
    partHsCodeConfirmedBy: part.hsCodeConfirmedBy ?? null,
    partHsCodePurpose: part.hsCodePurpose ?? null,
    partHsCodeState: part.hsCodeState ?? null,
    partId: part.id,
    partName: part.name,
    partType: part.partType,
    partMaterial: part.material,
    partPosition: part.position ?? 0,
    partPostProcessing: part.postProcessing,
    quantity: part.quantity || 1,
    weightKgPerPiece: part.weightKg,
    vatRate: 0,
    partCustomerNote: part.customerNote,
    partHsCode: part.hsCode,
    partHsCodeName: part.hsCodeName,
    partInternalComment: part.comments,
    partIsExpress: part.isExpress,
    partIsPreQuote: part.isPreQuoted,
    partIsSamplesNeeded: part.isSamplesNeeded,
    partMpNeeded: part.isMeasurementProtocolNeeded,
    partPreQuotedByLabel: part.preQuotedBy?.label,
    partPreQuotedComment: part.preQuotedComment,
    partSamplesComment: part.samplesComment,
    partSamplesQuantity: part.samplesQuantity,
    productionRemark: part.productionRemark,
  };
};
