import { CurrencyEnum } from '__generated__/types';
import { Store } from 'antd/lib/form/interface';
import Notification from 'components/UI/Notification';
import { objectBorder } from 'components/UI/variables';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import {
  InvoiceState,
  IPaymentRelation,
  OutInvoicePublishedFormValues,
  ServerGetPublishedInvoice,
  ServerUpdatePublishedInvoice,
} from 'interfaces';
import { action, observable } from 'mobx';
import moment from 'moment';
import { routesApi } from 'routes/api';
import UpdatableStore from 'stores/shared/UpdatableStore';
import { camelizeKeys } from 'utils/axios';

import { mapServerPublishedInvoice } from './utils';

class DealOutInvoicePublishedStore extends UpdatableStore {
  formValues: Store = {};

  @observable data: ServerGetPublishedInvoice = {
    cash_collection_comment: '',
    currency: '',
    cash_collection_status: null,
    credit_note: false,
    credit_note_reason: null,
    credit_notes: [],
    datev_comment: '',
    intacct_comment: '',
    is_published_in_ca: false,
    is_storno: false,
    negotiated_due_date: null,
    number: '',
    payment_relations: [],
    payment_terms_date: null,
    pdf: '',
    remark: undefined,
    send_reminders: false,
    state: InvoiceState.Draft,
    total_amount_brutto: '',
    total_amount_netto: '',
  };

  @observable isAccountantSystemLocked = false;

  @observable initialValues: OutInvoicePublishedFormValues = {
    cashCollectionComment: '',
    cashCollectionStatus: undefined,
    datevComment: '',
    datevVatCode: undefined,
    intacctComment: '',
    isPublishedInCA: false,
    negotiatedDueDate: null,
    paidAt: undefined,
    paymentRelations: [],
    paymentTermsDate: null,
    registeredAt: undefined,
    remark: undefined,
    sachkontoNumber: undefined,
    sendReminders: false,
    xometryVatNumber: undefined,
  };

  @observable paymentRelations: IPaymentRelation[] | null = null;

  @observable toBePaidCurrency: CurrencyEnum | undefined = undefined;

  @observable toBePaidAmount: string | number | undefined = undefined;

  @observable toBePaidInDifferentCurrency: boolean | undefined = false;

  @action setToBePaidCurrency = (toBePaidCurrency: CurrencyEnum | undefined) => {
    this.toBePaidCurrency = toBePaidCurrency;
  };

  @action setToBePaidAmount = (toBePaidAmount: string | number | undefined) => {
    this.toBePaidAmount = toBePaidAmount;
  };

  @action setToBePaidInDifferentCurrency = (toBePaidInDifferentCurrency: boolean | undefined) => {
    this.toBePaidInDifferentCurrency = toBePaidInDifferentCurrency;
  };

  mapData = (data: unknown): ServerUpdatePublishedInvoice =>
    mapServerPublishedInvoice(data as OutInvoicePublishedFormValues & { paymentRelations: IPaymentRelation[] });

  @action setPaymentRelations = (paymentRelations: IPaymentRelation[] | null) => {
    this.paymentRelations = paymentRelations;
  };

  @action assignOnShowSuccess = (payload: ServerGetPublishedInvoice): void => {
    this.data = payload;

    const defaultValues = {
      cashCollectionComment: this.data.cash_collection_comment,
      cashCollectionStatus: this.data.cash_collection_status,
      datevComment: this.data.datev_comment,
      intacctComment: this.data.intacct_comment,
      isPublishedInCA: this.data.is_published_in_ca,
      negotiatedDueDate: this.data.negotiated_due_date ? moment(this.data.negotiated_due_date) : null,
      paidAt: this.data.paid_at ? moment(this.data.paid_at) : undefined,
      paymentTermsDate: this.data.payment_terms_date ? moment(this.data.payment_terms_date) : null,
      registeredAt: this.data.registered_at ? moment(this.data.registered_at) : undefined,
      sendReminders: this.data.send_reminders,

      toBePaidAmount: this.data.to_be_paid_amount_money?.amount,
      toBePaidCurrency: this.data.to_be_paid_amount_money?.currency_code,
      toBePaidInDifferentCurrency: this.data.to_be_paid_in_different_currency,
    };

    const valuesEu = {
      ...defaultValues,
      datevVatCode: this.data.datev_vat_code,
      remark: this.data.remark,
      sachkontoNumber: this.data.sachkonto_number,
      xometryVatNumber: camelizeKeys(this.data.xometry_vat_number),
    };

    const valuesConfig = {
      [Regions.EU]: valuesEu,
      [Regions.INDIA]: defaultValues,
      [Regions.TR]: defaultValues,
      [Regions.UK]: defaultValues,
    };

    this.setToBePaidAmount(this.data.to_be_paid_amount_money?.amount);
    this.setToBePaidCurrency(this.data.to_be_paid_amount_money?.currency_code);
    this.setToBePaidInDifferentCurrency(this.data.to_be_paid_in_different_currency);

    this.initialValues = valuesConfig[CURRENT_REGION];

    this.isAccountantSystemLocked = !!this.initialValues.registeredAt;
    this.rootStore.uiStore.setContentBorderColor(
      // eslint-disable-next-line camelcase
      objectBorder[this.data?.credit_note ? 'g' : 'r'],
    );
  };

  @action initLoad = (dealId: string, invoiceId: string): void => {
    this.showStatus.isFinished = false;

    this.show(routesApi.dealOutcomingInvoicePath(dealId, invoiceId), {
      published: true,
    });
  };

  @action updateSettings = (dealId: string, invoiceId: string, formValues: Store): void => {
    this.formValues = formValues;
    this.rootStore.pageLoaderStore.enable('Updating Settings');

    const newValues = { ...formValues, paymentRelations: this.paymentRelations };

    this.update(routesApi.dealOutcomingInvoicePath(dealId, invoiceId), newValues);
  };

  @action onUpdateSuccess = (): void => {
    this.updateStatus.isFinished = true;
    this.updateStatus.isProcessing = false;

    this.isAccountantSystemLocked = !!this.formValues.registeredAt;

    Notification.Success('Invoice has been updated');

    this.rootStore.activityRecordStore.refetch();
    this.rootStore.pageLoaderStore.disable();
    location.reload();
  };
}

export default DealOutInvoicePublishedStore;
