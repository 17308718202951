import { ProductionStageEnum } from '__generated__/types';
import { DealPipedriveStatus } from 'interfaces';

export const dealPipedriveStatusOptions = Object.values(DealPipedriveStatus).map((status) => ({
  value: status,
  label: status,
}));

/** Deal Pipedrive status */
export enum PipedriveStatusEnum {
  /** deleted */
  Deleted = 'deleted',
  /** lost */
  Lost = 'lost',
  /** open */
  Open = 'open',
  /** won */
  Won = 'won',
}

export const dealProductionStageTranslations: Record<ProductionStageEnum, string> = {
  [ProductionStageEnum.Draft]: 'Draft',
  [ProductionStageEnum.Pending]: 'Pending',
  [ProductionStageEnum.NeedCalculate]: 'Need Calculate',
  [ProductionStageEnum.Calculated]: 'Calculated',
  [ProductionStageEnum.Submitted]: 'Submitted',
  [ProductionStageEnum.ProdProcessing]: 'Processing',
  [ProductionStageEnum.ProdPlacing]: 'Placing',
  [ProductionStageEnum.ProdPlaced]: 'Placed',
  [ProductionStageEnum.ProdShipped2x]: 'Shipped 2X',
  [ProductionStageEnum.ProdWarehouse]: 'Warehouse',
  [ProductionStageEnum.ProdShipped2c]: 'Shipped 2C',
  [ProductionStageEnum.ProdDelivered]: 'Delivered',
  [ProductionStageEnum.Won]: 'Won',
  [ProductionStageEnum.Lost]: 'Lost',
};

export const dealProductionStageOptions = [
  ProductionStageEnum.Draft,
  ProductionStageEnum.Pending,
  ProductionStageEnum.NeedCalculate,
  ProductionStageEnum.Calculated,
  ProductionStageEnum.Submitted,
  ProductionStageEnum.ProdProcessing,
  ProductionStageEnum.ProdPlacing,
  ProductionStageEnum.ProdPlaced,
  ProductionStageEnum.ProdShipped2x,
  ProductionStageEnum.ProdWarehouse,
  ProductionStageEnum.ProdShipped2c,
  ProductionStageEnum.ProdDelivered,
  ProductionStageEnum.Won,
  ProductionStageEnum.Lost,
].map((value) => ({
  value,
  label: dealProductionStageTranslations[value],
}));

export const activeProductionStageOptions = [
  ProductionStageEnum.Draft,
  ProductionStageEnum.Pending,
  ProductionStageEnum.NeedCalculate,
  ProductionStageEnum.Calculated,
  ProductionStageEnum.Submitted,
  ProductionStageEnum.ProdProcessing,
  ProductionStageEnum.ProdPlacing,
  ProductionStageEnum.ProdPlaced,
  ProductionStageEnum.ProdShipped2x,
  ProductionStageEnum.ProdWarehouse,
  ProductionStageEnum.ProdShipped2c,
  ProductionStageEnum.ProdDelivered,
].map((value) => ({
  value,
  label: dealProductionStageTranslations[value],
}));
