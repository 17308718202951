/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { notification } from 'antd';
import { dateTimeFormat } from 'components/utils/formatters';
import {
  CancelJobValues,
  CloneJobProps,
  IDealLot,
  IJob,
  IJobPosition,
  JobPositionsByKey,
  NewJobFormValues,
  Provider,
} from 'interfaces';
import { each, filter, map } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import uniqid from 'uniqid';
import omsApi, { camelizeKeys, decamelizeKeys, handleErrorMessage, requestBuilder } from 'utils/axios';

class JobStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = false;

  @observable positionsLoading = false;

  @observable currentJob: IJob | undefined;

  @observable tablePositions: JobPositionsByKey = {};

  @observable editingPositionKey = '';

  @observable partnersLoading = false;

  @observable selectedPartners: Provider[] = [];

  @observable isFetchingFile = false;

  @action setLoading = (isLoading: boolean): void => {
    this.loading = isLoading;
  };

  @action setCurrentJob = (currentJob: IJob): void => {
    this.currentJob = currentJob;
  };

  @action setPositionsLoading = (isLoading: boolean): void => {
    this.positionsLoading = isLoading;
  };

  @action setPartnersLoading = (isLoading: boolean): void => {
    this.partnersLoading = isLoading;
  };

  @action setEditingPositionKey = (key: string): void => {
    this.editingPositionKey = key;
  };

  @action updatePositionAndCloseEdit = (key: string, position: IJobPosition): void => {
    this.tablePositions[key] = {
      ...(this.tablePositions[key] || {}),
      ...position,
    };

    this.setEditingPositionKey('');
  };

  @action deletePosition = (key: string): void => {
    delete this.tablePositions[key];
  };

  @action addPositions = (positions: IJobPosition[]): void => {
    each(positions, (position: IJobPosition) => {
      const { key, lotId } = position;
      const existsLotIds = map(this.tablePositions, 'lotId');

      if (!existsLotIds.includes(lotId)) {
        this.tablePositions[key] = position;
      }
    });
  };

  @action clearPositions = (): void => {
    this.tablePositions = {};
  };

  @action clearJob = (): void => {
    this.currentJob = {} as IJob;
  };

  @action setPartners = (partners: Provider[]): void => {
    this.selectedPartners = partners;
  };

  @action resetPartners = async (partnerIds: number[]): Promise<void> => {
    this.setPartnersLoading(true);
    const providers = partnerIds && partnerIds.length ? await this.rootStore.providersStore.byIds(partnerIds) : [];
    this.setPartners(providers);
    this.setPartnersLoading(false);
  };

  @action setIsFetchingFile = (isFetchingFile: boolean): void => {
    this.isFetchingFile = isFetchingFile;
  };

  @action zipCleanedFiles = async () => {
    if (!this.currentJob) {
      return;
    }

    this.setIsFetchingFile(true);

    const request = requestBuilder({ responseType: 'blob' });

    try {
      const baseName = `J-${this.rootStore.dealStore.data.pipedriveId ?? ''}-${this.currentJob.id}-drawings`;
      const response = await request.get(
        `${routesApi.dealJobPath(String(this.currentJob.dealId), String(this.currentJob.id))}/download_zip`,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${baseName}.zip`);

      document.body.appendChild(link);

      link.click();
    } catch {
      notification.error({
        message: 'An error has occured while compressing files',
      });
    }

    this.setIsFetchingFile(false);
  };

  @action downloadPdfFile = async (type: 'download_td_pdf') => {
    if (!this.currentJob) {
      return;
    }

    this.setIsFetchingFile(true);

    const request = requestBuilder({ responseType: 'blob' });

    const names = {
      download_td_pdf: 'tech-details',
    };

    try {
      const baseName = `J-${this.rootStore.dealStore.data.pipedriveId ?? ''}-${this.currentJob.id}-${names[type]}`;
      const response = await request.get(
        `${routesApi.dealJobPath(String(this.currentJob.dealId), String(this.currentJob.id))}/${type}`,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${baseName}.pdf`);

      document.body.appendChild(link);

      link.click();
    } catch {
      notification.error({
        message: 'An error has occured while compressing files',
      });
    }

    this.setIsFetchingFile(false);
  };

  @action create = async (dealId: number, data: NewJobFormValues): Promise<any> => {
    const url = routesApi.dealJobsPath(`${dealId}`);
    this.setLoading(true);

    try {
      const response = await omsApi.post(url, {
        job: this._mapFromToData(data),
      });
      window.location.href = `${routes.jobsPath(`${dealId}`)}/${response.data.id}/edit`;
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };

  @action update = async (dealId: number, id: number, data: IJob, options?: any): Promise<any> => {
    const url = routesApi.dealJobPath(`${dealId}`, `${id}`);
    this.setLoading(true);

    try {
      await omsApi.put(url, { job: this._mapFromToData(data) });
      if (options && options.publish) {
        await this.publish(dealId, id);
      }

      window.location.href = `${routes.jobsPath(`${dealId}`)}/${id}/edit`;
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
    }
  };

  @action cancel = async (dealId: string, id: string, values: CancelJobValues): Promise<void> => {
    this.setLoading(true);

    try {
      await omsApi.post(`${routesApi.dealJobPath(`${dealId}`, `${id}`)}/cancel`, decamelizeKeys(values));

      this.setLoading(false);

      window.location.href = `${routes.jobsPath(`${dealId}`)}/${id}/edit`;
    } catch (e: unknown) {
      this.setLoading(false);

      throw e;
    }
  };

  @action reactivate = async (dealId: string, id: string, date: moment.Moment): Promise<void> => {
    this.setLoading(true);

    try {
      await omsApi.post(`${routesApi.dealJobPath(dealId, id)}/reactivate`, {
        publication_end: dateTimeFormat(date),
      });

      window.location.href = `${routes.jobsPath(`${dealId}`)}/${id}/edit`;

      this.setLoading(false);
    } catch {
      this.setLoading(false);
    }
  };

  @action clone = async (dealId: string, id: string, values: CloneJobProps): Promise<void> => {
    const url = [routesApi.dealJobPath(dealId, id), 'clone'].join('/');

    this.setLoading(true);

    try {
      const response = await omsApi.post(url, decamelizeKeys(values));
      const { payload } = response.data;

      this.setLoading(false);

      window.location.href = [routes.jobsPath(dealId), payload.id, 'edit'].join('/');
    } catch (err: any) {
      notification.error(err);

      this.setLoading(false);

      throw err;
    }
  };

  @action publish = async (dealId: number, id: number): Promise<void> => {
    this.setLoading(true);
    try {
      await omsApi.post(`${routesApi.dealJobPath(`${dealId}`, `${id}`)}/publish`);
    } catch (err) {
      const receivedMessage = handleErrorMessage(err);
      notification.error({ message: receivedMessage });
      this.setLoading(false);
      throw err;
    }
  };

  @action fetchJob = async (dealId: number, id: number): Promise<any> => {
    const url = routesApi.dealJobPath(`${dealId}`, `${id}`);
    this.setLoading(true);

    try {
      const response = await omsApi.get(url);
      this.setLoading(false);
      const { payload } = response.data;

      this.clearJob();
      this.clearPositions();

      return this._mapToEdit(payload);
    } catch (err: any) {
      notification.error(err);
      this.setLoading(false);
      throw err;
    }
  };

  @computed get tablePositionsArray(): IJobPosition[] {
    return Object.values(this.tablePositions);
  }

  @computed get selectedPartnerIds(): number[] {
    return map(this.selectedPartners, 'id');
  }

  @computed get positionsPrice(): number {
    return this.tablePositionsArray.reduce((sum, position) => sum + position.quantity * position.price, 0);
  }

  @computed get positionsCustomerPrice(): number {
    return this.tablePositionsArray.reduce((sum, position) => sum + position.quantity * position.customerPrice, 0);
  }

  _assignPositions = (lots: IDealLot[]): void => {
    const items: IJobPosition[] = lots.map((lot) => ({
      id: undefined,
      key: uniqid(),
      lotId: lot.id,
      material: lot.part.material,
      postProcessing: lot.part.postProcessing,
      partDetails: lot.productionRemark,
      name: lot.part.name,
      partId: lot.partId,
      part: lot.part,
      quantity: lot.quantity,
      customerPrice: lot.customerPrice,
      providerPrice: lot.providerPrice,
      price: lot.providerPrice,
      files: lot.files,
      tags: lot.tags,
    }));

    this.addPositions(items);
    this.setPositionsLoading(false);
  };

  _mapFromToData = (data: NewJobFormValues | IJob): any => {
    const newData = {
      ...data,
      positions: filter(this.tablePositionsArray, (position: IJobPosition) => !position._destroy),
    };

    return decamelizeKeys(newData);
  };

  _mapToEdit = (payload: any): IJob => {
    const data = camelizeKeys(payload);
    const positions: IJobPosition[] = [];

    each(data.positions, (position: IJobPosition) => {
      positions.push({
        ...position,
        key: uniqid(),
      });
    });

    this.addPositions(positions);
    void this.resetPartners(data.selectedPartners);

    const currentJob = {
      ...data,
      ownerIdOption: data.owner
        ? {
            label: data.owner.name,
            value: data.owner.id,
            name: data.owner.name,
            key: data.owner.id,
            imageUrl: data.owner.imageUrl,
          }
        : {},
      positions: {},
    };

    this.setCurrentJob(currentJob);

    return currentJob;
  };
}

export default JobStore;
