import { createQuery } from 'components/dashboards/shared/Counters/utils';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

import { TypeRouteId } from './api';

const WAREHOUSE_QC_ID: Record<Regions, string> = {
  [Regions.EU]: '13e04010-b4bb-4f1e-9a8e-be56fc154e77',
  /** TODO: INDIA LAUNCH */
  [Regions.INDIA]: '13e04010-b4bb-4f1e-9a8e-be56fc154e77',
  [Regions.TR]: '49b1e3a6-bf19-4f0b-9e79-fbd8447379d5',
  [Regions.UK]: '13e04010-b4bb-4f1e-9a8e-be56fc154e77',
};

const retoolRootUrl = 'https://xometryeu.retool.com/embedded/public';
const vatValidationUrl = () => `${retoolRootUrl}/cd24dadf-4a68-47f5-ae1d-e8cc7955c459`;
const dealOverviewUrl = () => `${retoolRootUrl}/b3153f0d-b9cd-4c12-bd36-9370763c304f`;
const retoolSharedUrl = () => `${retoolRootUrl}/b8095b5c-0d9a-4144-bbd8-7a8a309e0ab9`;
const partnerRatingUrl = () => `${retoolRootUrl}/2865c767-cbc4-4bfb-af98-c1eeeb45cc8b`;
const partnerShippingUrl = () => `${retoolRootUrl}/b8095b5c-0d9a-4144-bbd8-7a8a309e0ab9`;
const warehouseQC = () => `${retoolRootUrl}/${WAREHOUSE_QC_ID[CURRENT_REGION]}`;

const salesforceRoot = 'https://xometry-eu.lightning.force.com/lightning/r';
const salesforceCaseUrl = (caseId: TypeRouteId) => `${salesforceRoot}/Case/${caseId}/view`;
const salesforceOpportunityUrl = (opportunityId: TypeRouteId) => `${salesforceRoot}/Opportunity/${opportunityId}/view`;
const salesforceReport = (reportId: TypeRouteId) => `${salesforceRoot}/Report/${reportId}/view`;
const salesforceReportById = (reportId: TypeRouteId, fv0: TypeRouteId, region?: string) =>
  `${salesforceReport(reportId)}?fv0=${fv0}${region ? `&fv1=${region}` : ''}`;

const salesforceReportByIdSideBar = (reportId: TypeRouteId, fv0: TypeRouteId, region?: string) =>
  `${salesforceReport(reportId)}?fv0=D-${fv0}${region ? `&fv1=${region}` : ''}`;

const PNMsDashboards = () => 'https://xometry.looker.com/boards/99';

const confluenceUrl = 'https://xometry.atlassian.net/wiki';
const workspacesQuerySpec = () => `${confluenceUrl}/spaces/XomEUTech/pages/3050766461/Workspace+query+language`;

export interface ServiceDeskAccessRequestArgs {
  /** Summary field in the service desk form */
  summary?: string;
  /** Description field in the service desk form */
  description?: string;
}
const serviceDeskAccessRequest = (args?: ServiceDeskAccessRequestArgs) => {
  const query = createQuery({ summary: args?.summary?.slice(0, 200), description: args?.description?.slice(0, 200) });
  const queryString = query ? `?${query}` : '';

  return 'https://xometry.atlassian.net/servicedesk/customer/portal/14/group/44/create/180' + queryString;
};

export default {
  vatValidationUrl,
  dealOverviewUrl,
  retoolSharedUrl,
  salesforceCaseUrl,
  salesforceOpportunityUrl,
  salesforceReport,
  salesforceReportById,
  salesforceReportByIdSideBar,
  partnerRatingUrl,
  partnerShippingUrl,
  warehouseQC,
  PNMsDashboards,

  workspacesQuerySpec,
  serviceDeskAccessRequest,
};
