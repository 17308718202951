import { CurrenciesEnum } from '__generated__/types';
import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { Notification } from 'components/UI';
import { Payment, PaymentPosition, PaymentPositionsByKey, PaymentSource, PaymentStateType } from 'interfaces';
import { action, computed, get as _get, observable, toJS } from 'mobx';
import moment from 'moment';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import uniqid from 'uniqid';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';
import { XMoney } from 'utils/money';

import { completeBankRefund } from './Payment.api';

interface AxiosError {
  response?: {
    data?: {
      errors?: {
        source?: {
          base?: string;
        };
      };
    };
  };
}

interface CreatePaymentValues {
  currency?: CurrenciesEnum;
  state: PaymentStateType;
  paidAt: string | null;
  sourceType: string;
  sourceId: string;
  billingAccountId: number;
  datevComment: string;
  intacctComment: string;
  primary: boolean;
  amountBrut?: string;
  isRefund?: boolean;
  bankDetails?: {
    bankAccountHolderName: string;
    bankAccountNumber: string;
    bankCode: string;
  };
}

interface IPaymentRelation {
  id?: number | null;
  outcomingInvoiceId: number;
  totalAmountBrutto: number;
}

class PaymentStore {
  rootStore: RootStore;

  currentPosition = 0;

  @observable isFetching = false;

  @observable isSourcesFetching = false;

  @observable isPositionsFetching = false;

  @observable isPaymentFetching = false;

  @observable sources: PaymentSource[] = [];

  @observable currentSource: PaymentSource = {} as PaymentSource;

  @observable editingKey = '';

  @observable tablePositions: PaymentPositionsByKey = {};

  @observable currentPayment: Payment = {} as Payment;

  @observable paymentRelations: IPaymentRelation[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action reset = () => {
    this.sources = [];
    this.currentSource = {} as PaymentSource;
    this.editingKey = '';
    this.tablePositions = {} as PaymentPositionsByKey;
    this.currentPayment = {} as Payment;
    this.currentPosition = 0;
  };

  @action setPaymentRelations = (paymentRelations: IPaymentRelation[]) => {
    this.paymentRelations = paymentRelations;
  };

  @action setCurrentSourceBy = (number: string): PaymentSource => {
    const currentSource = this.sources.find((source) => source.number === number) || ({} as PaymentSource);

    const currentSourceWithCurrency = {
      ...currentSource,
      currency: currentSource.amountBrutMoney.currencyCode,
    };

    const newCurrentSource = currentSource.currency ? currentSource : currentSourceWithCurrency;

    this.currentSource = newCurrentSource || ({} as PaymentSource);

    return this.currentSource;
  };

  // Fetch deal object as payment sources
  @action fetchSources = async (dealId: number | string) => {
    this.setIsSourceFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.get(routesApi.dealPaymentSourcesPath(dealId));
      const payload = (response.data?.payload || []) as unknown;

      const collection: PaymentSource[] = camelizeKeys(payload);

      this.setIsSourceFetching(false);

      this.mergeSources(collection);
    } catch {
      this.setIsSourceFetching(false);
    }
  };

  // Merge Payment Sources
  @action private mergeSources = (sources: PaymentSource[]) => {
    this.sources = sources;
  };

  @action fetch = async (dealId: number, paymentId: number) => {
    this.setIsFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.get(routesApi.dealPaymentPath(dealId, paymentId));
      const payload = response.data?.payload;
      const payment: Payment = camelizeKeys(payload);

      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.setIsFetching(false);
    } catch {
      this.setIsFetching(false);
    }
  };

  @action assignPayment = (payment: Payment) => {
    this.currentPayment = payment;
  };

  // Create new payment
  @action create = async (values: CreatePaymentValues) => {
    const dealId = this.rootStore.dealStore.data.id;

    this.setIsFetching(true);

    try {
      const response = await api.post<{ payload: Payment }>(
        routesApi.dealPaymentsPath(String(dealId)),
        decamelizeKeys(values),
      );

      this.reset();

      const payment = response.data?.payload;

      this.rootStore.history.push(routes.editPaymentPath(String(dealId), payment.id));

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();
      this.setIsFetching(false);

      notification.success({
        message: 'Created',
      });
    } catch {
      this.setIsFetching(false);
    }
  };

  @action cancel = async () => {
    this.setIsFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.post(
        routesApi.paymentCancelPath(this.currentPayment.id),
      );

      const payload = response.data?.payload;
      const payment: Payment = camelizeKeys(payload);

      this.reset();
      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();
      this.setIsFetching(false);

      notification.success({
        message: `${payment.number} - has been canceled`,
      });

      this.rootStore.activityRecordStore.refetch();
    } catch {
      this.setIsFetching(false);
    }
  };

  @action update = async (values: Payment) => {
    const dealId = this.rootStore.dealStore.data.id;
    this.setIsFetching(true);

    const updateAttributes = {
      ...values,
      invoiceRelations: this.paymentRelations,
    };

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.patch(
        routesApi.dealPaymentPath(dealId, this.currentPayment.id),
        decamelizeKeys(updateAttributes),
      );

      const payment: Payment = camelizeKeys(response.data?.payload);

      this.reset();
      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();
      this.setIsFetching(false);

      notification.success({
        message: `${payment.number} has been updated!`,
      });

      this.rootStore.activityRecordStore.refetch();
    } catch {
      await this.fetch(this.currentPayment.dealId, this.currentPayment.id);

      this.setIsFetching(false);
    }
  };

  @action succeed = async (values: Payment) => {
    this.setIsFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.post(
        routesApi.paymentSucceedPath(this.currentPayment.id),
        decamelizeKeys(values),
      );

      const payment: Payment = camelizeKeys(response.data?.payload);

      this.reset();
      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();
      this.setIsFetching(false);

      notification.success({
        message: `${payment.number} has been completed!`,
      });
    } catch {
      this.setIsFetching(false);
      Notification.Error('Failed to complete payment');
    }
  };

  @action completeStripeRefund = async (params: Payment) => {
    this.setIsFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.post(
        routesApi.paymentStripeRefundPath(this.currentPayment.id),
        decamelizeKeys({ ...params, refundAmount: params.amountBrut }),
      );

      const payment: Payment = camelizeKeys(response.data?.payload);

      this.reset();
      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();

      this.setIsFetching(false);

      notification.success({
        message: `${payment.number} has been completed!`,
      });
    } catch (e) {
      let message = (e as AxiosError)?.response?.data?.errors?.source?.base;

      if (!message) message = 'Something went wrong';

      notification.error({ message });
      console.error(e);
      this.setIsFetching(false);
    }
  };

  @action completePaypalRefund = async (params: Payment) => {
    this.setIsFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.post(
        routesApi.paymentPaypalRefundPath(this.currentPayment.id),
        decamelizeKeys({ ...params, refundAmount: params.amountBrut }),
      );
      const payment: Payment = camelizeKeys(response.data?.payload);

      this.reset();
      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();

      this.setIsFetching(false);

      notification.success({
        message: `${payment.number} has been completed!`,
      });
    } catch (e) {
      let message = (e as AxiosError)?.response?.data?.errors?.source?.base;

      if (!message) message = 'Something went wrong';

      notification.error({ message });
      console.error(e);
      this.setIsFetching(false);
    }
  };

  @action completeBankRefund = async (params: Payment) => {
    this.setIsFetching(true);

    try {
      const payment = await completeBankRefund(this.currentPayment.id, params);

      this.assignPayment(payment);
      this.mergePositions(payment?.positions || []);

      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();

      notification.success({
        message: `${payment.number} has been completed!`,
      });
    } catch {
      await this.fetch(this.currentPayment.dealId, this.currentPayment.id);
    }

    this.setIsFetching(false);
  };

  @action private setIsFetching = (value: boolean): void => {
    this.isFetching = value;
  };

  @action private setIsSourceFetching = (value: boolean): void => {
    this.isSourcesFetching = value;
  };

  @action private setIsPositionsFetching = (value: boolean): void => {
    this.isPositionsFetching = value;
  };

  // POSITIONS

  private incCurrentPosition = (inc = 10): number => {
    this.currentPosition += inc;

    return this.currentPosition;
  };

  @action fetchSourcePositions = async () => {
    const dealId = this.rootStore.dealStore.data.id;
    this.setIsPositionsFetching(true);

    try {
      const response: AxiosResponse<{ payload: unknown }> = await api.get(
        `${routesApi.dealPaymentSourcesPosPath(dealId)}?type=${this.currentSource.type}&id=${this.currentSource.id}`,
      );

      const positions = camelizeKeys(response.data?.payload);

      this.mergePositions(positions as PaymentPosition[]);

      this.setIsPositionsFetching(false);
    } catch {
      this.setIsPositionsFetching(false);
    }
  };

  @action mergePositions = (positions: PaymentPosition[]): void => {
    positions.forEach((pos: PaymentPosition) => {
      const key = uniqid();

      this.incCurrentPosition();

      this.tablePositions[key] = {
        ...pos,
        key,
        position: pos.position || this.currentPosition,
      };
    });
  };

  @computed get tablePositionsArray(): PaymentPosition[] {
    return Object.values(this.tablePositions);
  }

  @action setEditingKey = (name: string): void => {
    this.editingKey = name;
  };

  @action addPosition = (): PaymentPosition => {
    const key = uniqid();
    const positionNumber = this.incCurrentPosition();
    const currency = this.currentSource.currency || this.tablePositionsArray[0].pricePerPieceMoney.currencyCode;

    const newPosition = {
      name: '',
      quantity: 1,
      pricePerPiece: 0,
      pricePerPieceMoney: XMoney(0, currency).toIMoney(),
      providerPrice: 0,
      vatRate: this.rootStore.dealStore.data.vat,
      position: positionNumber,
      key,
    };

    this.tablePositions[key] = newPosition;

    return newPosition;
  };

  @action delete = (positionId: string): void => {
    delete this.tablePositions[positionId];
  };

  @action updatePositionAndCloseEdit = (key: string, position: PaymentPosition): void => {
    const currency = this.currentSource.currency || this.tablePositionsArray[0].pricePerPieceMoney.currencyCode;

    this.tablePositions[key] = {
      ...position,
      pricePerPieceMoney: XMoney(position.pricePerPiece, currency).toIMoney(),
    };

    this.setEditingKey('');
  };

  @_get getSources = () => toJS(this.sources);

  @_get getPaymentSources = () =>
    this.sources.filter(
      (source) =>
        source.type === 'Deal::Payment' && /PI/.test(source.number) && source.state === PaymentStateType.completed,
    );

  @_get getPositions = () => toJS(this.tablePositions);

  get formattedPaidAt() {
    return this.currentPayment.paidAt ? moment(this.currentPayment.paidAt).format('DD.MM.YYYY') : null;
  }

  get isPartiallyRefunded() {
    return this.currentPayment.refundedBrutto > 0 && !this.currentPayment.fullyRefunded;
  }
}

export default PaymentStore;
