import { CurrencyEnum } from '__generated__/types';
import { FunctionComponent, SVGProps } from 'react';

export enum Regions {
  EU = 'EU',
  UK = 'UK',
  TR = 'TR',
  INDIA = 'INDIA',
}

export enum RegionLocales {
  EN = 'en',
  TR = 'tr',
}

export enum AccountingIds {
  datevId = 'datevId',
  intacctId = 'intacctId',
}

export enum AccountingIdNames {
  DATEV = 'DATEV',
  INTACCT = 'INTACCT',
}

export enum AccountingIdCommentNames {
  datevComment = 'datevComment',
  intacctComment = 'intacctComment',
}

export interface RegionData<Region extends Regions = Regions> {
  region: Region;
  orgName: string;
  name: string;
  shortName: string;
  currency: CurrencyEnum;
  locale?: RegionLocales;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  host: string;
  customersHost: string;
  partnersHost: string;
  headerColor: string;
}

export type RegionsData = { [K in Regions]: RegionData<K> };
