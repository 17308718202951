import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

export const UK = 'United Kingdom';
export const NORTHERN_IRELAND = 'Northern Ireland';
export const TURKEY = 'Turkey';
export const INDIA = 'India';

interface BaseCountry {
  name: string;
  code: string;
  region?: string;
  partner?: boolean;
}

interface EuCountry extends BaseCountry {
  region: 'EU';
  vatRate: number;
}

type Country = BaseCountry | EuCountry;

export const countries: Country[] = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT', region: 'EU', vatRate: 20 },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE', region: 'EU', vatRate: 21 },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bonaire', code: 'BQ', partner: false },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG', region: 'EU', vatRate: 20 },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Canary Islands – Spain', code: 'IC' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR', region: 'EU', vatRate: 25 },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curacao', code: 'CW', partner: false },
  { name: 'Cyprus', code: 'CY', region: 'EU', vatRate: 19 },
  { name: 'Czechia', code: 'CZ', region: 'EU', vatRate: 21 },
  { name: 'Denmark', code: 'DK', region: 'EU', vatRate: 25 },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE', region: 'EU', vatRate: 20 },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI', region: 'EU', vatRate: 24 },
  { name: 'France', code: 'FR', region: 'EU', vatRate: 20 },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE', region: 'EU', vatRate: 19 },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR', region: 'EU', vatRate: 24 },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU', region: 'EU', vatRate: 27 },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE', region: 'EU', vatRate: 23 },
  { name: NORTHERN_IRELAND, code: 'XI', region: 'EU', vatRate: 23 },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT', region: 'EU', vatRate: 22 },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kosovo', code: 'XK', partner: false },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV', region: 'EU', vatRate: 21 },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT', region: 'EU', vatRate: 21 },
  { name: 'Luxembourg', code: 'LU', region: 'EU', vatRate: 17 },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT', region: 'EU', vatRate: 18 },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC', region: 'EU', vatRate: 20 },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME', partner: false },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL', region: 'EU', vatRate: 21 },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL', region: 'EU', vatRate: 23 },
  { name: 'Portugal', code: 'PT', region: 'EU', vatRate: 23 },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO', region: 'EU', vatRate: 19 },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Barthelemy', code: 'BL', partner: false },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS', partner: false },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten', code: 'SX', partner: false },
  { name: 'Slovakia', code: 'SK', region: 'EU', vatRate: 22 },
  { name: 'Slovenia', code: 'SI', region: 'EU', vatRate: 22 },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'South Sudan', code: 'SS', partner: false },
  { name: 'Spain', code: 'ES', region: 'EU', vatRate: 21 },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE', region: 'EU', vatRate: 25 },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: TURKEY, code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: UK, code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const euCountries = countries.filter((c) => c.region === 'EU') as EuCountry[];
export const nonEUCountries = countries.filter((c) => c.region !== 'EU') as EuCountry[];
export const trCountries = countries.filter((c) => c.name === TURKEY);
export const nonTRCountries = countries.filter((c) => c.name !== TURKEY);
export const ukCountries = countries.filter((c) => [NORTHERN_IRELAND, UK].includes(c.name));
export const nonUKCountries = countries.filter((c) => ![NORTHERN_IRELAND, UK].includes(c.name));
export const indiaCountries = countries.filter((c) => c.name === INDIA);
export const nonIndiaCountries = countries.filter((c) => c.name !== INDIA);

const getCountryOpts = (countriesForOpts: Country[]) =>
  countriesForOpts.map((country) => ({
    value: country.name,
    label: country.name,
  }));

export const countriesOpts = getCountryOpts(countries);
export const euCountriesOpts = getCountryOpts(euCountries);
export const nonEUCountriesOpts = getCountryOpts(nonEUCountries);
export const trCountriesOpts = getCountryOpts(trCountries);
export const nonTRCountriesOpts = getCountryOpts(nonTRCountries);
export const ukCountriesOpts = getCountryOpts(ukCountries);
export const nonUKCountriesOpts = getCountryOpts(nonUKCountries);
export const indiaCountriesOpts = getCountryOpts(indiaCountries);
export const nonIndiaCountriesOpts = getCountryOpts(nonIndiaCountries);

export const trVatValidation = {
  name: TURKEY,
  vatRegex: /^[0-9]{10,11}$/,
  message: 'Vat ID must contain 10 or 11 digits',
};

export const countriesVatValidations = [
  { name: 'Austria', vatRegex: /^(AT)U[0-9]{8}$/, message: 'ATU and 8 digits' },
  { name: 'Belgium', vatRegex: /^(BE)[0-9]{10}$/, message: 'BE and 10 digits' },
  { name: 'Bulgaria', vatRegex: /^(BG)[0-9]{9,10}$/, message: 'BG and 9-10 digits' },
  { name: 'Cyprus', vatRegex: /^(CY)[0-9A-Z]{9}$/, message: 'CY and 9 characters' },
  { name: 'Czech Republic', vatRegex: /^(CZ)[0-9]{8,10}$/, message: 'CZ and 8-10 digits' },
  { name: 'Germany', vatRegex: /^(DE)[0-9]{9}$/, message: 'DE and 9 digits' },
  { name: 'Denmark', vatRegex: /^(DK)[0-9]{8}$/, message: 'DK and 8 digits' },
  { name: 'Estonia', vatRegex: /^(EE)[0-9]{9}$/, message: 'EE and 9 digits' },
  { name: 'Greece', vatRegex: /^(EL|GR)[0-9]{9}$/, message: 'EL or GR and 9 digits' },
  {
    name: 'Spain',
    vatRegex: /^(ES)[0-9A-Z][0-9]{7}[0-9A-Z]$/,
    message: 'ES and 1 letter or digit and 7 digits and 1 letter or digit',
  },
  { name: 'Finland', vatRegex: /^(FI)[0-9]{8}$/, message: 'FI and 8 digits' },
  { name: 'France', vatRegex: /^(FR)[0-9A-Z]{2}[0-9]{9}$/, message: 'FR and 2 letters or digits and 9 digits' },
  {
    name: 'United Kingdom',
    vatRegex: /^(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/,
    message: 'GB and 9 and 3 digits or 2 letters and 3 digits',
  },
  { name: 'Hungary', vatRegex: /^(HU)[0-9]{8}$/, message: 'HU and 8 digits' },
  {
    name: 'Ireland',
    vatRegex: /^(IE)[0-9A-Z]{7}[A-Z]{1,2}$/,
    message: 'IE and 8 or 9 characters Includes one or two alphabetical characters',
  },
  { name: 'Italy', vatRegex: /^(IT)[0-9]{11}$/, message: 'IT and 11 digits' },
  { name: 'Lithuania', vatRegex: /^(LT)([0-9]{9}|[0-9]{12})$/, message: 'LT and 9 or 12 digits' },
  { name: 'Luxembourg', vatRegex: /^(LU)[0-9]{8}$/, message: 'LU and 8 digits' },
  { name: 'Latvia', vatRegex: /^(LV)[0-9]{11}$/, message: 'LV and 11 digits' },
  { name: 'Malta', vatRegex: /^(MT)[0-9]{8}$/, message: 'MT and 8 digits' },
  { name: 'Netherlands', vatRegex: /^(NL)[0-9]{9}B[0-9]{2}$/, message: 'NL and 9 digits and B and 2 digits' },
  { name: 'Poland', vatRegex: /^(PL)[0-9]{10}$/, message: 'PL and 10 digits' },
  { name: 'Portugal', vatRegex: /^(PT)[0-9]{9}$/, message: 'PT and 9 digits' },
  { name: 'Romania', vatRegex: /^(RO)[0-9]{2,10}$/, message: 'RO and 2-10 digits' },
  { name: 'Sweden', vatRegex: /^(SE)[0-9]{12}$/, message: 'SE and 12 digits' },
  { name: 'Slovenia', vatRegex: /^(SI)[0-9]{8}$/, message: 'SI and 8 digits' },
  { name: 'Slovakia', vatRegex: /^(SK)[0-9]{10}$/, message: 'SK and 10 digits' },
  trVatValidation,
];

export const isEuropean = (country?: string) => euCountries.find((c) => c.name === country);

export const domesticCountries = {
  [Regions.EU]: euCountries.map((c) => c.name),
  [Regions.UK]: [UK, NORTHERN_IRELAND],
  [Regions.TR]: [TURKEY],
  [Regions.INDIA]: [INDIA],
}[CURRENT_REGION];

export const isDomestic = (country?: string | null) => domesticCountries.find((c) => c === country) != null;

export default countries;
