import { PlusOutlined } from '@ant-design/icons';
import { Spin, Upload, UploadProps } from 'antd';
import clsx from 'clsx';
import React from 'react';

import s from './Uploader.module.less';

interface Props extends UploadProps {
  label?: string;
  className?: string;
  buttonClassName?: string;
  loading?: boolean;
  size?: 'small' | 'medium';
}

export const Uploader = (props: Props) => {
  const { label = 'Upload', className, buttonClassName, loading, size = 'medium', ...rest } = props;

  return (
    <Upload className={clsx(s.upload, className)} {...rest}>
      <div
        className={clsx(s.upload__button, buttonClassName, s[`upload__button_${size}`], {
          [s.upload__button_disabled]: rest.disabled,
        })}
      >
        {loading ? (
          <Spin className={s.spin} />
        ) : (
          <div className={s.upload__buttonLabel}>
            <PlusOutlined />
            <div>{label}</div>
          </div>
        )}
      </div>
    </Upload>
  );
};
