import { Money, PartTypeEnum, StorageLocationEnum } from '__generated__/types';
import { IMoney } from '@xometry/ui';
import { Moment } from 'moment-timezone';

import { IAttachedFile } from './AttachedFilesStore';
import { MeasurementProtocols } from './PartsStore';

export enum StorageState {
  NotProcessed = 'not_processed',
  InProcessing = 'in_processing',
  PartiallyProcessed = 'partially_processed',
  ProcessedForRepack = 'processed_for_repack',
  ProcessedForReturn = 'processed_for_return',
  ProcessedForStorage = 'processed_for_storage',
  ProcessedAndSplit = 'processed_and_split',
  TestPart = 'test_part',
  ReproductionPlanned = 'reproduction',
  Cancellation = 'cancellation',
}

export enum StorageType {
  Inventory = 'inventory',
  Utilization = 'utilization',
  Canceled = 'canceled',
}

export interface StorageSummary {
  positions: number;
  count: number;
  weight: number;
  totalCustomerPriceMoney: IMoney;
  totalProviderPriceMoney: IMoney;
}

export interface StorageStateOptions {
  value: string;
  label: string;
}

export interface StorageSource {
  sourceId: number;
  sourceType: string;
  sourceName: string;
  sourceComment: string;
}

export interface StorageLot {
  _key?: string;
  id: number;
  partId: number;
  partName: string;
  partType: PartTypeEnum;
  partMaterial: string;
  partPosition: number;
  partPostProcessing: string;
  partCustomerNote?: string;
  partInternalComment?: string;
  partIsExpress?: boolean;
  partIsPreQuote?: boolean;
  partPreQuotedByLabel?: string;
  partPreQuotedComment?: string;
  partIsSamplesNeeded?: boolean;
  partSamplesQuantity?: number;
  partSamplesComment?: string;
  partMpNeeded?: MeasurementProtocols;
  quantity: number;
  productionRemark?: string;
  customerPrice: number;
  customerPriceMoney: Money | IMoney;
  providerPrice: number;
  providerPriceMoney: Money | IMoney;
  files: IAttachedFile[];
  isPlaced: boolean;
  isUsed: boolean;
  vatRate: number;
  weightKgPerPiece: number;
}

export interface StoragePart {
  id: number;
  position: number;
}

export interface Storage {
  id: number;
  dealId: number;
  number: string;
  location: StorageLocationEnum;
  issueDate: string;
  storageType: StorageType;
  processingState: string;
  internalComment?: string;

  relatedDocuments: StorageRelatedDoc[];
  relatedQualityControls: StorageRelatedQC[];
  referringDocuments: StorageRelatedDoc[];
  referringQualityControls: StorageRelatedQC[];

  lots: StorageLot[];
  positionsLeft: StorageLeftLot[];
  updatedAt: string;
}

export interface StorageLeftLot {
  lotId: number;
  quantityInitial: number;
  quantityLeft: number;
  children: StorageLeftLotChild[];
}

export interface StorageLeftLotChild {
  id: number;
  objectId: number;
  objectName: string;
  objectType: string;
  quantity: number;
}

export interface StorageRelatedDoc {
  objectId: number;
  objectType: string;
  name: string;
  lots: StorageRelatedDocLot[];
  canceled: boolean;
}

export interface StorageRelatedQC {
  objectId: number;
  objectType: string;
  name: string;
  canceled: boolean;
}

export interface StorageRelatedDocLot {
  id: number;
  parentId: number;
  partId: number;
  quantity: number;
}
export interface StorageFormValues {
  type: StorageType;
  state: string;
  issueDate: Moment;
  internalComment?: string;
  location: string;
}

export interface StorageCreateShippingParams {
  direction: string;
  isPartial: boolean;
  sourcePo?: number;
}

export interface StorageCreateStorageParams {
  isPartial: boolean;
  storageType: StorageType;
  processingState?: StorageState;
  location: StorageLocationEnum;
}
