import styled from '@emotion/styled';
import { bc, colors } from 'components/UI/variables';

import { Modal as ErpModal } from './extend';

interface ModalProps {
  isBilling?: boolean;
  isFullPage?: boolean;
  isFullHeight?: boolean;
  isWarning?: boolean;
  onCancel?: () => void;
}

const MODAL_HEADER_HEIGHT = 57;
const MODAL_FULL_HEIGHT_PADDING = 80;

export const Modal = styled(ErpModal)<ModalProps>`
  ${({ isBilling }) => (isBilling ? 'min-width: 950px;' : '')}
  max-width: calc(100vw - 16px);
  ${({ isFullPage }) => isFullPage && 'width: calc(100vw - 16px) !important; padding-bottom: 0px; top: 10px;'}

  color: ${colors.gray9};

  .ant-modal-header {
    background-color: ${bc.modal.header};
    padding: 16px 24px;
    cursor: move;
    border: none;
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.gray8};

    ${({ isWarning }) => isWarning && `color: ${bc.errorDark}`}
  }

  .ant-modal-body {
    padding: 24px;
    ${({ isFullPage }) => isFullPage && 'padding: 20px;'}
    ${({ isFullHeight }) =>
      isFullHeight &&
      `
      height: calc(100vh - ${MODAL_HEADER_HEIGHT}px - ${MODAL_FULL_HEIGHT_PADDING}px);
      overflow-y: auto;
      `}
  }

  .ant-modal-footer {
    border: none;
  }

  .ant-modal-close-x {
    color: ${colors.gray9};
  }
`;

export const ModalTitle = styled.div`
  color: ${bc.modal.title};
`;
