import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';

import s from './PartnerChangeRequestModal.module.less';
import { Change } from './types';

const RENDER_FIELD_NAMES: Record<string, string> = {
  legalName: 'Company legal name',
  vatId: 'VAT ID',
  datevKonto: 'DATEV ID',
  intacctId: 'INTACCT ID',
  bankAccount: 'Bank account',
  bankAccountHolderName: 'Bank account holder name',
  bankCode: 'Bank code',
  bankCountry: 'Bank country',
  bankName: 'Bank name',
  country: 'Country',
  city: 'City',
  zip: 'Postalcode',
  notVatPayer: 'Not VAT payer',
  payoutTransferType: 'Payout transfer type',
};

const columns: ColumnsType<Change> = [
  {
    title: 'Field name',
    dataIndex: 'fieldName',
    key: 'fieldname',
    width: '210px',
    render: (value: string) => RENDER_FIELD_NAMES[value] || value,
  },
  {
    title: 'Old value',
    dataIndex: 'currentValue',
    key: 'currentValue',
    width: '348px',
  },
  {
    title: 'New value',
    dataIndex: 'newValue',
    key: 'newValue',
    width: '348px',
  },
];

export const ChangesTable: FC<{ title: string; changes: Change[] }> = ({ title, changes }) => {
  if (!changes.length) {
    return null;
  }

  return (
    <div className={s.changesTable}>
      <div className={s.changesBlockTitle}>{title}</div>
      <Table dataSource={changes} columns={columns} size="small" bordered pagination={false} />
    </div>
  );
};
