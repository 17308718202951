import { FilterPreset, ObjectType } from 'interfaces';

export interface FilterObject {
  label: string;
  objectType: ObjectType;
  hidden?: boolean;
  sub?: boolean;
  children?: FilterObject[];
}

export const filterObjects: FilterObject[] = [
  {
    label: 'Enquiry',
    objectType: ObjectType.E,
    children: [
      {
        label: 'Quotation',
        objectType: ObjectType.Q,
        hidden: true,
      },
    ],
  },
  {
    label: 'RFQ',
    objectType: ObjectType.RFQ,
  },
  {
    label: 'Order Confirmation',
    objectType: ObjectType.OC,
  },
  {
    label: 'Payments & Ref.',
    objectType: ObjectType.Payment,
  },
  {
    label: 'Jobs',
    objectType: ObjectType.J,
  },
  {
    label: 'Job Offers',
    objectType: ObjectType.JO,
  },
  {
    label: 'Shippings',
    objectType: ObjectType.SH,
    children: [
      {
        label: 'Delivery',
        objectType: ObjectType.DL,
        hidden: true,
      },
    ],
  },
  {
    label: 'Quality Controls',
    objectType: ObjectType.QC,
  },
  {
    label: 'Storage/Utilization',
    objectType: ObjectType.SU,
  },
  {
    label: 'Out. Invoices & CN',
    objectType: ObjectType.OI,
  },
  {
    label: 'Production Orders',
    objectType: ObjectType.PO,
    children: [
      {
        label: 'Inc. Invoices & CN ',
        objectType: ObjectType.II,
      },
      {
        label: 'Payouts & Ref.',
        objectType: ObjectType.PZ,
      },
    ],
  },
  {
    label: 'Claims',
    objectType: ObjectType.CL,
  },
];

export const filterObjectTypes: ObjectType[] = [];
export const visibleFilterObjectTypes: ObjectType[] = [];

filterObjects.forEach(({ children, objectType }) => {
  filterObjectTypes.push(objectType);
  if (children) {
    children.forEach(({ objectType }) => filterObjectTypes.push(objectType));
  }
});

export const objectsByFilterPreset = {
  [FilterPreset.FINANCE]: [
    ObjectType.OC,
    ObjectType.Payment,
    ObjectType.OI,
    ObjectType.PO,
    ObjectType.II,
    ObjectType.CL,
    ObjectType.PZ,
  ],
  [FilterPreset.LOGISTIC]: [ObjectType.SH, ObjectType.DL, ObjectType.DL, ObjectType.SU, ObjectType.QC],
  [FilterPreset.PRODUCTION]: [
    ObjectType.OC,
    ObjectType.PO,
    ObjectType.SH,
    ObjectType.QC,
    ObjectType.SU,
    ObjectType.DL,
    ObjectType.II,
    ObjectType.CL,
  ],
  [FilterPreset.SALES]: [
    ObjectType.E,
    ObjectType.Q,
    ObjectType.OC,
    ObjectType.Payment,
    ObjectType.PZ,
    ObjectType.PO,
    ObjectType.OI,
    ObjectType.CL,
    ObjectType.RFQ,
  ],
  [FilterPreset.ALL]: filterObjectTypes,
  [FilterPreset.NONE]: [],
};

export const filterPresetList = [
  FilterPreset.NONE,
  FilterPreset.ALL,
  FilterPreset.SALES,
  FilterPreset.LOGISTIC,
  FilterPreset.FINANCE,
  FilterPreset.PRODUCTION,
];
