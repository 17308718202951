import { SortDirectionEnum } from '__generated__/types';
import { ErpPermissionGroup } from 'collections/authorization';
import {
  ColumnSettings,
  NativeColumnSettings,
  QueryName,
  QueryNameKey,
  SyntheticColumnSettings,
} from 'components/Workspaces/collections';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

export interface ColIndexMap {
  rowNumber: number;
  checkbox: number;
  expandButton: number;
}

export type FilterNextOperator = 'AND' | 'OR';

export enum FilterPatternName {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Excludes = 'EXCLUDES',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  In = 'IN',
  Equal = '=',
  NotEqual = '!=',
  LessThan = '<',
  GreaterThan = '>',
}

export enum FilterKeywords {
  EndOfMonth = 'end_of_month',
  False = 'false',
  Me = 'me',
  Now = 'now',
  Null = 'null',
  StartOfMonth = 'start_of_month',
  StartOfYear = 'start_of_year',
  Today = 'today',
  True = 'true',
}

export enum CustomFilterPattern {
  IsNull = 'IS NULL',
  IsNotNull = 'IS NOT NULL',
  IsTrue = 'IS TRUE',
  IsFalse = 'IS FALSE',
}

export enum GraphQLScalarType {
  BigInt = 'BigInt',
  Boolean = 'Boolean',
  Float = 'Float',
  ID = 'ID',
  ISO8601 = 'ISO8601DateTime',
  ISO8601Date = 'ISO8601Date',
  Int = 'Int',
  String = 'String',
}

export interface GraphQLFieldScalarType {
  name: GraphQLScalarType | string;
  kind: 'SCALAR' | 'ENUM';
}

export interface GraphQLFieldObjectType {
  name: string;
  kind: 'OBJECT';
  fields: GraphQLField[];
}

export type GraphQLFieldBaseType = GraphQLFieldScalarType | GraphQLFieldObjectType;

export interface GraphQLFieldWrapperType {
  name: undefined;
  kind: string;
  ofType: GraphQLFieldType | GraphQLFieldWrapperType;
}

export type GraphQLFieldType = GraphQLFieldBaseType | GraphQLFieldWrapperType;

export interface GraphQLField {
  name: string;
  description?: string;
  type: GraphQLFieldType;
}

export interface WorkspaceGraphQLFieldScalarType {
  name: GraphQLScalarType | string;
  kind: 'SCALAR' | 'ENUM';
}

export interface WorkspaceGraphQLFieldObjectType {
  name: string;
  kind: 'OBJECT';
  fields: WorkspaceGraphQLField[];
}

export type WorkspaceGraphQLFieldType = WorkspaceGraphQLFieldScalarType | WorkspaceGraphQLFieldObjectType;

export type WorkspaceGraphQLScalarField = GraphQLField & {
  type: WorkspaceGraphQLFieldScalarType;
  list: boolean;
  enum: boolean;
};

export type WorkspaceGraphQLObjectField = GraphQLField & {
  type: WorkspaceGraphQLFieldObjectType;
  list: boolean;
  enum?: false;
};

export type WorkspaceGraphQLField = WorkspaceGraphQLScalarField | WorkspaceGraphQLObjectField;

export interface IAttributesResponse {
  __type: {
    fields: GraphQLField[];
  };
}

export interface BaseColumn {
  enabled: boolean;
  key: string;
  title: string;
  type: string;
  settings?: ColumnSettings<any>;
}

export type SyntheticColumn = {
  field?: undefined;
  subfield?: undefined;
  settings: SyntheticColumnSettings<any>;
  synthetic: true;
} & BaseColumn;

export type NativeColumn = {
  field: WorkspaceGraphQLField;
  subField?: WorkspaceGraphQLField;
  settings: NativeColumnSettings<any>;
  synthetic?: false | undefined;
} & BaseColumn;

export type Column = SyntheticColumn | NativeColumn;

export interface IDataItem {
  key: string;
  data: Record<string, any>;
}

export interface IFilter {
  attribute?: string;
  pattern?: FilterPatternName | CustomFilterPattern;
  values: any[];
}

export interface IFilterPattern {
  arity: number;
  label: string;
  value: FilterPatternName | CustomFilterPattern;
}

export interface IFiltersForm {
  filters: IFilter[];
}

export interface IPaginationParam {
  page: number;
  pageSize: number;
}

export interface ISortParam {
  sortBy: string;
  direction: SortDirectionEnum;
}

export interface ITopFilter {
  attribute: string;
  inputPrefix?: React.ReactNode;
  pattern: FilterPatternName;
}

export interface ColumnHeaderDecoratorProps {
  title: string;
}

export type ColumnHeaderDecorator = (key: string, props: ColumnHeaderDecoratorProps) => React.ReactNode;

export const filterPatterns: {
  [key in FilterPatternName]: IFilterPattern;
} = {
  [FilterPatternName.Between]: {
    arity: 2,
    value: FilterPatternName.Between,
    label: 'is between',
  },
  [FilterPatternName.Contains]: {
    arity: 1,
    value: FilterPatternName.Contains,
    label: 'contains',
  },
  [FilterPatternName.Excludes]: {
    arity: 1,
    value: FilterPatternName.Excludes,
    label: 'does not contain',
  },
  [FilterPatternName.StartsWith]: {
    arity: 1,
    value: FilterPatternName.StartsWith,
    label: 'starts with',
  },
  [FilterPatternName.EndsWith]: {
    arity: 1,
    value: FilterPatternName.EndsWith,
    label: 'ends with',
  },
  [FilterPatternName.In]: {
    arity: 1,
    value: FilterPatternName.In,
    label: 'is in',
  },
  [FilterPatternName.Equal]: {
    arity: 1,
    value: FilterPatternName.Equal,
    label: 'is equal to',
  },
  [FilterPatternName.NotEqual]: {
    arity: 1,
    value: FilterPatternName.NotEqual,
    label: 'is not equal to',
  },
  [FilterPatternName.GreaterThan]: {
    arity: 1,
    value: FilterPatternName.GreaterThan,
    label: 'is greater than',
  },
  [FilterPatternName.LessThan]: {
    arity: 1,
    value: FilterPatternName.LessThan,
    label: 'is less than',
  },
};

export const customFilterPatterns: {
  [key in CustomFilterPattern]: IFilterPattern;
} = {
  [CustomFilterPattern.IsNull]: {
    arity: 0,
    value: CustomFilterPattern.IsNull,
    label: 'is null',
  },
  [CustomFilterPattern.IsNotNull]: {
    arity: 0,
    value: CustomFilterPattern.IsNotNull,
    label: 'is not null',
  },
  [CustomFilterPattern.IsTrue]: {
    arity: 0,
    value: CustomFilterPattern.IsTrue,
    label: 'is true',
  },
  [CustomFilterPattern.IsFalse]: {
    arity: 0,
    value: CustomFilterPattern.IsFalse,
    label: 'is false',
  },
};

export const allFilterPatterns = { ...filterPatterns, ...customFilterPatterns };

export const filterPatternsByType: {
  [key in GraphQLScalarType]: IFilterPattern[];
} = {
  [GraphQLScalarType.Boolean]: [
    customFilterPatterns[CustomFilterPattern.IsTrue],
    customFilterPatterns[CustomFilterPattern.IsFalse],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.Float]: [
    filterPatterns[FilterPatternName.Between],
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.NotEqual],
    filterPatterns[FilterPatternName.GreaterThan],
    filterPatterns[FilterPatternName.LessThan],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.ID]: [
    filterPatterns[FilterPatternName.Between],
    filterPatterns[FilterPatternName.In],
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.NotEqual],
    filterPatterns[FilterPatternName.GreaterThan],
    filterPatterns[FilterPatternName.LessThan],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.ISO8601]: [
    filterPatterns[FilterPatternName.Between],
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.NotEqual],
    filterPatterns[FilterPatternName.GreaterThan],
    filterPatterns[FilterPatternName.LessThan],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.ISO8601Date]: [
    filterPatterns[FilterPatternName.Between],
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.NotEqual],
    filterPatterns[FilterPatternName.GreaterThan],
    filterPatterns[FilterPatternName.LessThan],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.Int]: [
    filterPatterns[FilterPatternName.Between],
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.NotEqual],
    filterPatterns[FilterPatternName.GreaterThan],
    filterPatterns[FilterPatternName.LessThan],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.BigInt]: [
    filterPatterns[FilterPatternName.Between],
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.NotEqual],
    filterPatterns[FilterPatternName.GreaterThan],
    filterPatterns[FilterPatternName.LessThan],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],

  [GraphQLScalarType.String]: [
    filterPatterns[FilterPatternName.Equal],
    filterPatterns[FilterPatternName.Contains],
    filterPatterns[FilterPatternName.Excludes],
    filterPatterns[FilterPatternName.StartsWith],
    filterPatterns[FilterPatternName.EndsWith],
    filterPatterns[FilterPatternName.NotEqual],
    customFilterPatterns[CustomFilterPattern.IsNull],
    customFilterPatterns[CustomFilterPattern.IsNotNull],
  ],
};

const queryTypeNames: Record<QueryNameKey, string> = {
  [QueryName.BA]: 'WorkspacesBillingAccount',
  [QueryName.II]: 'WorkspacesIncomingInvoice',
  [QueryName.OI]: 'WorkspacesOutcomingInvoice',
  [QueryName.LI]: 'WorkspacesLogisticInvoice',
  [QueryName.QC]: 'WorkspacesQualityControl',
  [QueryName.PZ]: 'WorkspacesPayout',
  [QueryName.PI]: 'WorkspacesPayment',
  [QueryName.RF]: 'WorkspacesRefund',
  [QueryName.PR]: 'WorkspacesProvider',
  [QueryName.RfqPartners]: 'WorkspacesRfqPartner',
  [QueryName.SA]: 'ShippingAddress',
  [QueryName.HsCodes]: 'WorkspacesHsCode',
  [QueryName.SH]: 'WorkspacesShipping',
  [QueryName.SU]: 'WorkspacesStorage',
  [QueryName.OrganizationBalances]: 'WorkspacesOrganizationBalance',
  [QueryName.Organization]: 'WorkspacesOrganization',
  [QueryName.D]: 'WorkspacesDeal',
  [QueryName.Person]: 'WorkspacesPerson',
  [QueryName.E]: 'WorkspacesEnquiry',
  [QueryName.F]: 'WorkspacesFrameworkBatch',
  [QueryName.J]: 'WorkspacesJob',
  [QueryName.BP]: 'WorkspacesBankPayment',
  [QueryName.BPDraft]: 'WorkspacesBankPaymentsDraft',
  [QueryName.JO]: 'WorkspacesJobOffer',
  [QueryName.CL]: 'WorkspacesClaim',
  [QueryName.PO]: 'WorkspacesProviderOrder',
  [QueryName.User]: 'WorkspacesUser',
  [QueryName.Tasks]: 'WorkspacesTask',
  [QueryName.DraftingDealParts]: 'WorkspacesDraftingDealPart',
  [QueryName.DraftingEnquiryParts]: 'WorkspacesDraftingEnquiryPart',
  [QueryName.Coupons]: 'WorkspacesCoupon',
  [QueryName.MessageSubscriptions]: 'WorkspacesSubscription',
  [QueryName.Messages]: 'WorkspacesMessage',
  [QueryName.Emails]: 'WorkspacesEmailMessage',
  [QueryName.RfqOffers]: 'WorkspacesRfqOffer',
  [QueryName.Rfqs]: 'WorkspacesRfq',
  [QueryName.ProductionScheduleItems]: 'WorkspacesProductionScheduleItem',
};

const typeNameByRegion: Record<Regions, Record<QueryNameKey, string>> = {
  [Regions.EU]: {
    [QueryName.Coupons]: 'WorkspacesCoupon',
  },
  [Regions.INDIA]: {
    [QueryName.Coupons]: 'WorkspacesEnquiryPromotion',
  },
  [Regions.TR]: {
    [QueryName.Coupons]: 'WorkspacesEnquiryPromotion',
  },
  [Regions.UK]: {
    [QueryName.Coupons]: 'WorkspacesEnquiryPromotion',
  },
};

export const listQueryTypeNames = { ...queryTypeNames, ...typeNameByRegion[CURRENT_REGION] };

export interface PermissionsSettings {
  group: ErpPermissionGroup;
  filter: string;
  view?: string;
}
