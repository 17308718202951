import { Select, SelectProps } from 'antd';
import Tag from 'antd/lib/tag';
import clsx from 'clsx';
import { UserAvatarSize, UserView } from 'components/UI/UserView';
import { filterByLabel } from 'helpers/filterOption';
import React, { CSSProperties, useState } from 'react';

import s from './SelectUser.module.less';

type TagRender = SelectProps['tagRender'];

type Value = string | number;

export interface UserOption {
  label: string;
  value: Value;
  imageUrl?: string | null;
  disabled?: boolean;
}

export interface SelectUserProps<T extends UserOption> extends SelectProps<Value | Value[]> {
  value?: Value | Value[] | undefined | null;
  onChange?: (value: Value | Value[] | undefined) => void;
  options: T[];
  userAvatarSize?: UserAvatarSize;
  renderOption?: (option: T) => React.ReactNode;
}

export interface CustomCSS extends CSSProperties {
  '--padding-left': string;
}

const paddingLeftBySize: Record<UserAvatarSize, string> = {
  small: '24px',
  medium: '30px',
  'medium-large': '34px',
  large: '40px',
};

const tagRender: TagRender = ({ label, closable, onClose }) => {
  const onPreventMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} className={s.tag}>
      {label}
    </Tag>
  );
};

export const SelectUser = <T extends UserOption>(props: SelectUserProps<T>) => {
  const {
    value,
    onChange,
    options,
    userAvatarSize = 'medium',
    style,
    renderOption,
    className,
    onSearch,
    ...rest
  } = props;

  const [searchValue, setSearchValue] = useState(rest.searchValue);

  const needPaddingLeft = value && !searchValue && rest.mode !== 'multiple';
  const paddingLeft = needPaddingLeft ? paddingLeftBySize[userAvatarSize] : '0px';

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    onSearch?.(searchValue);
  };

  return (
    <Select
      allowClear
      showSearch
      {...rest}
      filterOption={filterByLabel}
      value={value}
      searchValue={searchValue}
      onSearch={handleSearch}
      className={clsx(s.select, className)}
      style={{ ...style, '--padding-left': paddingLeft } as CustomCSS}
      onChange={onChange}
      tagRender={tagRender}
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value} disabled={option.disabled} label={option.label}>
          {renderOption ? (
            renderOption(option)
          ) : (
            <UserView
              size={userAvatarSize}
              user={{ id: option.value, name: option.label, imageUrl: option.imageUrl }}
            />
          )}
        </Select.Option>
      ))}
    </Select>
  );
};
