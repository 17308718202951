import { podIdOptions as podOptions } from 'collections/users';
import { bc } from 'components/UI/variables';
import { DealPipedriveStatus, UserLocStore } from 'interfaces';
import { compact, map, sortBy, uniqBy } from 'lodash-es';
import { TagsQuery } from 'utils/graphql/queries/__generated__/tags';
import { GetUsersQuery } from 'utils/graphql/queries/__generated__/users';

import { FilterKeywords } from './General/shared/GeneralWorkspace/collections';

export interface Option {
  label: string;
  value: string;
  imageUrl?: string | null;
}

type User = NonNullable<GetUsersQuery['users']>[number];

export const formatNumber = (value?: number) => value?.toLocaleString('ru-RU')?.replace(',', '.');

export const formatUrl = (url: string) => (/^https?/.test(url) ? url : `http://${url}`);

export const prepareUsers = (users: GetUsersQuery['users']) =>
  uniqBy(
    sortBy(users, (u) => ((u.active ? 1 : 0) * 100 + (u.podId ?? 0)) * -1),
    'name',
  );

const addMeUserOption = (options: Option[], me: User | undefined): Option[] => {
  return [
    {
      label: 'Me',
      value: FilterKeywords.Me,
      imageUrl: me?.imageUrl,
    },
    ...options,
  ];
};

export const billingAccountTypeOptions = [
  {
    label: 'Corporate',
    value: 'Corporate',
  },
  {
    label: 'Private Person',
    value: 'Private Person',
  },
];

export const mapDealStatusStyle = (pipedriveStatus: string) => {
  switch (pipedriveStatus) {
    case DealPipedriveStatus.won:
      return { color: bc.success };
    case DealPipedriveStatus.open:
      return { color: bc.primary };
    default:
      return { color: bc.error };
  }
};

export const workspacesPodOptions = podOptions.map((option) => ({
  ...option,
  value: option.value.toString(),
}));

export const prepareTagsOptions = (tags: TagsQuery['tags']) =>
  (tags ?? []).map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));

export const prepareUserOptions = (
  users: GetUsersQuery['users'],
  { enableMeOption = true }: { enableMeOption?: boolean } = {},
) => {
  if (!users) return [];

  let userOptions: Option[] = map(prepareUsers(users), (u) => ({
    label: compact([u.name, u.podId && `POD: ${u.podId}`]).join(' | '),
    value: u.id.toString(),
    imageUrl: u.imageUrl,
  }));

  if (enableMeOption) {
    const meId = localStorage.getItem(UserLocStore.id);
    const me = users.find((u) => String(u.id) === meId);
    userOptions = addMeUserOption(userOptions, me);
  }

  return userOptions;
};

export const prepareUserNameOptions = (users: GetUsersQuery['users']) => {
  if (!users) return [];

  const userOptions = map(prepareUsers(users), (u) => ({
    label: compact([u.name, u.podId && `POD: ${u.podId}`]).join(' | '),
    value: u.name,
    imageUrl: u.imageUrl,
  }));

  const meId = localStorage.getItem(UserLocStore.id);
  const me = users.find((u) => String(u.id) === meId);

  return addMeUserOption(userOptions, me);
};

export const invertEnum = (enumObj: Record<string, string | number>) => {
  return Object.fromEntries(Object.entries(enumObj).map(([key, value]) => [value, key]));
};
