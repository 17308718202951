import { WorkspacesRfqOffer } from '__generated__/types';
import documentUrl from 'components/utils/documentUrl';
import { ObjectType } from 'interfaces';
import React from 'react';
import { Link } from 'react-router-dom';

export const dealIdDecorator = (rfqOffer: WorkspacesRfqOffer) => (
  <Link to={documentUrl(ObjectType.D, rfqOffer.dealId)} target="_blank" rel="noreferrer noopener">
    <b>{rfqOffer.dealNumber}</b>
  </Link>
);
