import { Part } from 'interfaces';
import { routesApi } from 'routes/api';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';

interface Response {
  payload: Part;
}

interface CustomerObjectInput {
  name?: string;
  customerNote?: string;
  returnRequired?: boolean;
  fileIds?: number[];
  position?: number;
  customerObjectPrice?: {
    amountCents: string;
    currencyCode: string;
  };
  itemMassKg?: number;
  material: string;
  dimensions?: { x?: number; y?: number; z?: number };
  comments?: string;
}

export const updateCustomerObject = async (
  dealId: string | number,
  customerObjectId: string | number,
  customerObject: CustomerObjectInput,
): Promise<Part> => {
  const decamelizedInput = decamelizeKeys(customerObject);
  const response = await api.patch<Response>(routesApi.customerObjectPath(dealId, customerObjectId), {
    customer_object: decamelizedInput,
  });
  const camelizedResponse = camelizeKeys(response.data);

  return camelizedResponse.payload;
};
