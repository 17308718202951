import { UserAvatar, UserAvatarProps } from '@xometry/ui';
import { Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

import s from './UserView.module.less';

export type UserAvatarSize = NonNullable<UserAvatarProps['size']>;

interface Props {
  className?: string;
  user:
    | {
        id: string | number;
        name: string;
        imageUrl?: string | null;
      }
    | null
    | undefined;
  size?: UserAvatarSize;
}

export const UserView: React.FC<Props> = (props) => {
  const { className, user, size } = props;

  if (!user) return null;

  return (
    <div className={clsx(s.root, className)}>
      <UserAvatar
        name={user.name || String(user.id).split('').join(' ')}
        className={s.user}
        imageUrl={user.imageUrl || undefined}
        size={size}
      />
      <Typography.Text className={s.name}>{user.name || user.id}</Typography.Text>
    </div>
  );
};
