import { ShippingSourceUnion } from 'interfaces/stores/Shipping';
import { routesApi } from 'routes/api';
import api, { camelizeKeys } from 'utils/axios';

interface Params {
  dealId: string | number;
  withCustomerObjects?: boolean;
}

interface Response {
  payload: ShippingSourceUnion[];
}

export const getShippingSources = async (params: Params): Promise<ShippingSourceUnion[]> => {
  const response = await api.get<Response>(routesApi.shippingsSourcesPath(params.dealId, params.withCustomerObjects));

  return camelizeKeys(response.data.payload);
};
