import { CurrencyEnum } from '__generated__/types';
import { ENV_APP_REGION } from 'constants/env';

import { EURegionConfig } from './configs/eu';
import { INDIARegionConfig } from './configs/india';
import { TRRegionConfig } from './configs/tr';
import { UKRegionConfig } from './configs/uk';
import { AccountingIdCommentNames, AccountingIdNames, AccountingIds, Regions, RegionsData } from './types';

export const RegionsConfig: RegionsData = {
  [Regions.EU]: EURegionConfig,
  [Regions.INDIA]: INDIARegionConfig,
  [Regions.TR]: TRRegionConfig,
  [Regions.UK]: UKRegionConfig,
};

export const CURRENT_REGION = RegionsConfig[ENV_APP_REGION as keyof typeof RegionsConfig]?.region;
export const CURRENCIES = Object.values(CurrencyEnum);

export const ACCOUNTING_ID_KEY: Record<Regions, AccountingIds> = {
  [Regions.EU]: AccountingIds.datevId,
  [Regions.INDIA]: AccountingIds.intacctId,
  [Regions.TR]: AccountingIds.intacctId,
  [Regions.UK]: AccountingIds.intacctId,
};

export const ACCOUNTING_ID_NAME: Record<Regions, AccountingIdNames> = {
  [Regions.EU]: AccountingIdNames.DATEV,
  [Regions.INDIA]: AccountingIdNames.INTACCT,
  [Regions.TR]: AccountingIdNames.INTACCT,
  [Regions.UK]: AccountingIdNames.INTACCT,
};

export const ACCOUNTING_ID_COMMENT_NAME: Record<Regions, AccountingIdCommentNames> = {
  [Regions.EU]: AccountingIdCommentNames.datevComment,
  [Regions.INDIA]: AccountingIdCommentNames.intacctComment,
  [Regions.TR]: AccountingIdCommentNames.intacctComment,
  [Regions.UK]: AccountingIdCommentNames.intacctComment,
};
