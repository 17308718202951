import { Part } from 'interfaces';
import { routesApi } from 'routes/api';
import api, { camelizeKeys } from 'utils/axios';

interface Response {
  payload: Part;
}

export const getCustomerObject = async (dealId: string | number, customerObjectId: string | number) => {
  const response = await api.get<Response>(routesApi.customerObjectPath(dealId, customerObjectId));

  return camelizeKeys(response.data.payload);
};
