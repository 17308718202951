import { UserRegionEnum } from '__generated__/types';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { map } from 'lodash-es';

const podIdsByRegion: Record<Regions, Record<number, string>> = {
  [Regions.EU]: {
    1: 'POD 1 DE',
    2: 'POD 2 3D',
    3: 'POD 3 FR',
    4: 'POD 4 EU EN',
    5: 'POD 5 IT/SP',
    6: 'POD 6 TR prod',
    7: 'POD 7 UK prod',
    8: 'POD 8 internal',
  },
  /** TODO: INDIA LAUNCH */
  [Regions.INDIA]: {
    1: 'POD 1',
    2: 'POD 2',
    3: 'POD 3',
    4: 'POD 4',
    5: 'POD 5',
  },
  [Regions.TR]: {
    1: 'POD 1',
    2: 'POD 2',
    3: 'POD 3',
    4: 'POD 4',
    5: 'POD 5',
  },
  [Regions.UK]: {
    1: 'POD 1',
    2: 'POD 2',
    3: 'POD 3',
    4: 'POD 4',
  },
};

const podIds = podIdsByRegion[CURRENT_REGION];

export const getPodName = (podId: number) => {
  return podIds[podId] || `POD ${podId}`;
};

export const podIdOptions = map(Object.entries(podIds), ([value, label]) => ({
  label,
  value: Number.parseInt(value, 10),
}));

const localesByRegion: Record<Regions, string[]> = {
  [Regions.EU]: ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'nl', 'no', 'pl', 'pt', 'sv', 'tr'],
  /** TODO: INDIA LAUNCH */
  [Regions.INDIA]: ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'nl', 'no', 'pl', 'pt', 'sv', 'tr'],
  [Regions.TR]: ['en', 'tr'],
  [Regions.UK]: ['en'],
};

export const localesOpts = localesByRegion[CURRENT_REGION].map((locale) => ({
  label: locale.toLocaleUpperCase(),
  value: locale,
}));

export const userRegionLabels: Record<UserRegionEnum, string> = {
  [UserRegionEnum.De]: 'DE',
  [UserRegionEnum.Fr]: 'FR',
  [UserRegionEnum.NordixBenelux]: 'Nordix/Benelux',
  [UserRegionEnum.Ukie]: 'UK/IE',
  [UserRegionEnum.Others]: 'Others',
};

export const userRegionOptions = Object.entries(userRegionLabels).map(([value, label]) => {
  return {
    value,
    label,
  };
});
