import { ObjectType } from 'interfaces';

import { defaultObjectsFilter } from './constants';
import { filterObjectTypes } from './filterObjects';

export const getFilterFromStorage = () => {
  const selectedObjectTypesRaw = localStorage.getItem('selectedObjectTypes') as string;

  if (selectedObjectTypesRaw) {
    const selectedObjectTypes = JSON.parse(selectedObjectTypesRaw) as Record<string, boolean>;

    // Remove non-existent object types
    Object.keys(selectedObjectTypes).forEach((objectType) => {
      if (objectType === 'canceled') {
        return;
      }

      if (!filterObjectTypes.includes(objectType as ObjectType)) {
        delete selectedObjectTypes[objectType];
      }
    });

    return selectedObjectTypes;
  }

  return defaultObjectsFilter;
};

export const saveFilterToStorage = (filter: Record<string, boolean>) => {
  localStorage.setItem('selectedObjectTypes', JSON.stringify(filter));
};
