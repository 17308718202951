import { Money } from '__generated__/types';
import { getRegionCurrency, getRegionCurrencySymbol } from 'config/regions/utils';
import { AuditState, ObjectType } from 'interfaces';
import { isEmpty, isNil, isNumber } from 'lodash-es';
import moment, { Moment } from 'moment';
import { routes } from 'routes';
import { getCurrencySymbol } from 'utils/money';

type OptionalInput<T> = T | null | undefined;
type OptionalDate = OptionalInput<Moment | Date | string>;

export const NA = '–';
export const DEFAULT_MONEY_VALUE = '0';

export const DATE_F = 'DD.MM.YYYY';
export const DATE_PLAIN = 'DDMMYYYY';
export const DATETIME_F = 'DD.MM.YYYY HH:mm';

export const decoratedSourceType = (
  sourceType: string,
  sourceId: string | number,
  pdDealId?: string | number | null,
) => {
  switch (sourceType) {
    case ObjectType.SH:
      return `SH-${pdDealId?.toString() ?? ''}-${sourceId}`;
    case ObjectType.PR:
      return `PR-${sourceId}`;
    case ObjectType.SU:
      return `SU-${pdDealId?.toString() ?? ''}-${sourceId}`;
    case ObjectType.Q:
      return `Q-${pdDealId?.toString() ?? ''}-${sourceId}`;
    case ObjectType.PO:
      return `PO-${pdDealId?.toString() ?? ''}-${sourceId}`;
    case ObjectType.Payment:
      return `Payment #${sourceId}`;
    case ObjectType.E:
      return `E-${pdDealId?.toString() ?? ''}-${sourceId}`;
    case ObjectType.DL:
      return `DL-${pdDealId?.toString() ?? ''}-${sourceId}`;
    case ObjectType.OI:
      return `OI #${sourceId}`;
    case ObjectType.II:
      return `II #${sourceId}`;
    case ObjectType.OC:
      return `OC-${pdDealId?.toString() ?? ''}`;
    default:
      return sourceType;
  }
};

export const objectLink = (sourceType: string, sourceId: number | string, dealPdId?: number | string) => {
  switch (sourceType) {
    case ObjectType.SH:
      return `${routes.smartRouterPath()}?search=sh${sourceId}`;
    case ObjectType.E:
      return `${routes.smartRouterPath()}?search=e${sourceId}`;
    case ObjectType.BA:
      return `${routes.smartRouterPath()}?search=ba${sourceId}`;
    case ObjectType.SA:
      return `${routes.smartRouterPath()}?search=sa${sourceId}`;
    case ObjectType.D:
      return `${routes.smartRouterPath()}?search=d${dealPdId?.toString() ?? ''}`;
    case ObjectType.SU:
      return `${routes.smartRouterPath()}?search=su${sourceId}`;
    case ObjectType.PO:
      return `${routes.smartRouterPath()}?search=po${sourceId}`;
    case ObjectType.DL:
      return `${routes.smartRouterPath()}?search=dl${sourceId}`;
    case ObjectType.Payment:
      return `${routes.smartRouterPath()}?search=pi${sourceId}`;
    case ObjectType.OC:
      return `${routes.smartRouterPath()}?search=oc${dealPdId?.toString() ?? ''}`;
    case ObjectType.PZ:
      return `${routes.smartRouterPath()}?search=pz${sourceId}`;
    case ObjectType.OI:
      return `${routes.smartRouterPath()}?search=oi${sourceId}`;
    case ObjectType.PR:
      return `${routes.smartRouterPath()}?search=pr${sourceId}`;
    case ObjectType.Person:
      return `${routes.smartRouterPath()}?search=p${sourceId}`;
    case ObjectType.RfqOffer:
      return `${routes.smartRouterPath()}?search=ro${sourceId}`;
    case ObjectType.Organization:
      return `${routes.smartRouterPath()}?search=o${sourceId}`;
    case ObjectType.MSH:
      return `${routes.editMasterShippingPath(sourceId)}`;
    default:
      return sourceType;
  }
};

export const partAuditState = (auditState: AuditState) => {
  switch (auditState) {
    case AuditState.New:
      return 'New';
    case AuditState.NeedInfo:
      return 'Need info';
    case AuditState.ReviewedHighRisk:
      return 'Reviewed, High Risk';
    case AuditState.ReviewedOk:
      return 'Reviewed, OK';
    case AuditState.AutomaticSkipped:
      return 'Automatic, Skipped';
    case AuditState.ReviewedReject:
      return 'Reviewed, Reject';
    default:
      return 'N/A';
  }
};

export const valueOrNa = <T>(value?: T): typeof NA | NonNullable<T> => {
  if (isNumber(value)) {
    return value as unknown as NonNullable<T>;
  }

  return isNil(value) || isEmpty(value) ? NA : (value as unknown as NonNullable<T>);
};

export interface CurrencyOpts {
  curr?: string;
  symbolizeCurrency?: boolean;
  formatted?: boolean;
  precision?: number;
}

/**
 * @deprecated Use Money from "src/config/money.ts" instead.
 */
export const moneyWithCurrency = (moneyObject?: Money | null, options?: CurrencyOpts) => {
  const amount = isNil(moneyObject) ? DEFAULT_MONEY_VALUE : moneyObject.amount;
  const currencyCode = isNil(moneyObject) ? getRegionCurrency() : moneyObject.currencyCode;

  return fMoney(amount, { ...options, symbolizeCurrency: true, curr: currencyCode });
};

/**
 * @deprecated Use Money from "src/config/money.ts" instead.
 */
export const fMoney = (value: OptionalInput<string | number>, options?: CurrencyOpts) => {
  const opts = {
    curr: options?.curr || getRegionCurrencySymbol(),
    formatted: options?.formatted,
    precision: options?.precision ?? 2,
  };

  const currency = options?.symbolizeCurrency ? getCurrencySymbol(opts.curr) : opts.curr;

  const withCurrency = (value: string | number, { prefix }: { prefix: string } = { prefix: '' }) =>
    currency === 'noop' ? `${prefix}${value}` : `${currency}\u00A0${prefix}${value}`;

  const initialValue =
    isNil(value) || Number.isNaN(Number(value)) ? DEFAULT_MONEY_VALUE : String(value).replace(',', '.');

  const paddedValue = parseFloat(initialValue).toFixed(opts.precision);

  const withSpaces = paddedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');

  return opts?.formatted
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: opts.precision,
      })
        .format(Number(value ?? DEFAULT_MONEY_VALUE))
        .replaceAll(',', '\u00A0')
        .replace('-$', withCurrency('', { prefix: '-' }))
        .replace('$', withCurrency(''))
    : withCurrency(withSpaces);
};

/**
 * @deprecated Use Money from "src/config/money.ts" instead.
 */
export const currency = fMoney;

/**
 * @deprecated Use Money from "src/config/money.ts" instead.
 */
export const monetize = (cents?: number, currency = getRegionCurrencySymbol()) =>
  cents == null ? undefined : `${getCurrencySymbol(currency)} ${Number(cents / 100) || 0}`;

export const percent = (value: any, toFixed = 1): string =>
  `${parseFloat(String(value).replace(',', '.')).toFixed(toFixed).replace(/\.0$/, '')}%`;

export const parseDate = (date: Moment | Date | string): Moment =>
  moment(date, ['YYYY-MM-DDTHH:mm:ssZ', 'DD-MM-YYYY HH:mm', 'YYYY-MM-DD HH:mm']);

export const addDays = (date: Moment, days: number) => date.add(days, 'days');

export const dateFormat = (date: OptionalDate, format = DATE_F) => {
  if (!date) return NA;

  return parseDate(date).format(format);
};

export const dateTimeFromNow = (date: OptionalDate) => {
  if (!date) return NA;

  return parseDate(date).fromNow();
};

export const dateFormatToBackend = (date: Moment) => date.format(DATE_F);

export const dateDecoratedFormat = <T>(date: T): string => {
  if (!date) return NA;

  const value = moment(date);
  const today = moment(new Date());
  const yesterday = moment(new Date()).subtract(1, 'days');

  if (value.format('DDMMYYYY') === today.format('DDMMYYYY')) {
    return `Today ${value.format('HH:mm')}`;
  }

  if (value.format('DDMMYYYY') === yesterday.format('DDMMYYYY')) {
    return `Yesterday ${value.format('HH:mm')}`;
  }

  return value.format(DATE_F);
};

export const dateTimeFormat = (date: OptionalDate, placeholder: string | null = NA) => {
  if (!date) return placeholder;

  return parseDate(date).format(DATETIME_F);
};

export const dateHoursDiffFormat = (rawStart: OptionalDate, rawEnd: OptionalDate) => {
  if (!rawStart || !rawEnd) return NaN;

  const start = parseDate(rawStart);
  const end = parseDate(rawEnd);

  const diff = moment.duration(end.diff(start)).asHours();

  if (diff < 0) {
    return 0;
  }

  return diff;
};

export const objectName = (type: string, object: any) => {
  let prefix = type;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
  let sufix = `#${object.id}`;

  switch (type) {
    case 'job':
      prefix = 'Job draft';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (object.state === 'active') {
        prefix = 'Active Job';
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (object.state === 'expired') {
        prefix = 'Expired Job';
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (object.state === 'placed') {
        prefix = 'Placed Job';
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (object.state === 'canceled') {
        prefix = 'Canceled Job';
      }

      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      sufix = object.jobNumber;
      break;
    case 'offer':
      prefix = 'Offer';
      break;
    default:
      break;
  }

  return `${prefix} ${sufix}`;
};

export const shortName = (longName: string | null, length: number) => {
  if (!longName || longName.length <= length) {
    return longName;
  }

  const prefixLength = Math.round(length / 2);
  const tailLength = prefixLength;

  return `${longName.substr(0, prefixLength - 1)}...${longName.substr(longName.length - tailLength + 1, tailLength)}`;
};

export const weightKg = (value?: string | number | null) => `${parseFloat((Number(value) ?? 0).toFixed(3))} kg`;

/**
 * @deprecated Use Money from "src/config/money.ts" instead.
 */
export const priceEuro = (value?: number) => `${(Number(value) ?? 0).toFixed(2)} €`;

export const businessDate = (days: number, from: Moment = moment()): Moment => {
  const date = from;
  let inc = 0;

  while (inc < days) {
    date.add(1, 'days');
    while (date.day() === 0 || date.day() === 6) {
      date.add(1, 'days');
    }

    inc += 1;
  }

  return date;
};

export const truncateMiddle = (str: string, length: number, middle = '...') => {
  if (str.length <= length) return str;

  return (
    str.slice(0, Math.floor(length / 2) - Math.floor(middle.length / 2)) +
    middle +
    str.slice(-1 * Math.ceil(length / 2) + Math.ceil(middle.length / 2))
  );
};

/**
 * Truncates file name with an ellipsis in the middle.
 * @param fileName Source file name.
 * @param charCount String length limit.
 */
export const fileNameEllipsis = (fileName: string, charCount: number): string => {
  if (fileName.length <= charCount) {
    return fileName;
  }

  const extDotIndex = fileName.lastIndexOf('.');

  if (extDotIndex === -1) {
    return `${fileName.slice(0, charCount - 1)}…`;
  }

  const extention = fileName.slice(extDotIndex);
  const name = fileName.slice(0, extDotIndex);

  const charsLeft = charCount - extention.length;

  const startLength = Math.round(charsLeft / 2);
  const endLength = charsLeft - 1 - startLength;

  return `${name.slice(0, startLength - 1)}…${name.slice(name.length - endLength - 1)}${extention}`;
};

/** Returns deal name by pipedriveId or dealId */
export const makeDealName = (dialId: string | number, pipedriveId?: string | number | null) => {
  if (pipedriveId != null) {
    return `D-${pipedriveId}`;
  }

  return `Deal ID: ${dialId}`;
};

export const getBusinessDaysBetweenDates = (to: string, from?: string) => {
  let workingDays = 0;
  const toMoment = moment(to);
  const fromMoment = from ? moment(from) : moment();

  while (fromMoment.isSameOrBefore(toMoment, 'day')) {
    if (fromMoment.day() !== 0 && fromMoment.day() !== 6) {
      workingDays++;
    }

    fromMoment.add(1, 'days');
  }

  return workingDays;
};

export const addBusinessDays = (from: Moment, days: number) => {
  const weekendDays = [6, 0];
  let countDays = 0;
  let currentDate = moment(from);

  while (countDays < days) {
    currentDate = currentDate.add(1, 'days');
    if (!weekendDays.includes(currentDate.day())) {
      countDays++;
    }
  }

  return currentDate;
};

interface InputNumberFormatterInfo {
  userTyping: boolean;
  input: string;
}

/**
 * InputNumber money formatter
 * Add commas to separate thousands
 */
export const moneyFormatter = (value: string | number | null | undefined, info: InputNumberFormatterInfo): string => {
  if (value === undefined || value === null || value === '') return '';

  let formattedValue = value;

  // Add .00 if user is not typing
  // 1000 -> 1000.00
  if (!info.userTyping) {
    formattedValue = Number(value).toFixed(2);
  }

  return `${formattedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const moneyFormatterWithCurrency =
  (currencySymbol: string) =>
  (value: string | number | null | undefined, info: InputNumberFormatterInfo): string => {
    if (value === '') {
      return '';
    }

    return `${moneyFormatter(value, info)} ${currencySymbol}`;
  };

/**
 * InputNumber money parser
 * Remove non-numeric characters except for the decimal point.
 * Keeps backend money-precision at 2 digits.
 */
export const moneyParser = (value: string | undefined): string | number => {
  if (value === undefined || value === null) return '0';

  const draft = `${value}`.replace(/[^0-9.-]/g, '');
  const probablyNumber = Number(draft);

  if (!Number.isNaN(probablyNumber)) {
    // Replace trailing zeroes like in '2.00' to '2';
    return probablyNumber.toFixed(2).replace(/\.00$/gm, '');
  }

  return draft;
};

/**
 * InputNumber parser
 * Remove non-numeric characters except for the decimal point.
 */
export const numberParser = (value: string | undefined): string | number => {
  if (value === undefined || value === null) return '0';

  let stringValue = String(value);

  if (stringValue.includes(',') && !stringValue.includes('.')) {
    stringValue = stringValue.replace(/,/g, '.');
  }

  return `${stringValue}`.replace(/[^0-9.-]/g, '');
};

/** InputNumber percent formatter */
export const percentFormatter = (value?: string | number | null): string => `${value || ''}%`;

/** InputNumber percent parser */
export const percentParser = (value?: string | null): number => {
  if (value === undefined || value === null) return 0;

  return Number(value.replace('%', ''));
};

/** Number formatter to format "12 345 678.91" */
export const formatBigNumber = (volume: number): string => {
  const formattedNumber = volume.toFixed(2);

  let integerPart = formattedNumber.split('.')[0];

  const decimalPart = formattedNumber.split('.')[1];

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${integerPart}.${decimalPart}`;
};
