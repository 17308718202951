import { Input, InputNumber, Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SelectUser } from 'components/shared/SelectUser';
import { numberParser } from 'components/utils/formatters';
import {
  allFilterPatterns,
  CustomFilterPattern,
  FilterPatternName,
  GraphQLScalarType,
} from 'components/Workspaces/General/shared/GeneralWorkspace/collections';
import { ValueInputsContainer } from 'components/Workspaces/General/shared/GeneralWorkspace/EditFiltersModal/Filters/styled';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React from 'react';

import DatePicker from './DatePicker';

type Value = string | number | undefined;

export type OptionsType = 'users' | 'default';

interface InputParams {
  index: number;
  onChange: (value: Value, index: number) => void;
  onArrayChange: (values: Value[]) => void;
  options?: { label: string; value: string; imageUrl?: string | null }[];
  multipleOptions?: boolean;
  optionsType?: OptionsType;
  readonly?: boolean;
  pattern: FilterPatternName | CustomFilterPattern;
  placeholder?: string;
  prefix?: React.ReactNode;
  size?: SizeType;
  type: string;
  value?: Value;
  values?: Value[];
  width?: number;
}

const attributeInput = ({
  index,
  onChange,
  onArrayChange,
  options,
  multipleOptions,
  readonly,
  pattern,
  placeholder,
  prefix,
  size,
  type,
  value,
  values,
  width,
  optionsType = 'default',
}: InputParams) => {
  const stringInput = (
    <Input
      disabled={readonly}
      key={index}
      placeholder={placeholder}
      prefix={prefix}
      size={size}
      style={{ width: width ?? 'auto' }}
      value={value}
      onChange={(v) => onChange(v.target.value, index)}
    />
  );

  if (options != null) {
    if (optionsType === 'users') {
      return (
        <SelectUser
          disabled={readonly}
          key={index}
          mode={multipleOptions ? 'multiple' : undefined}
          onChange={(v) => (multipleOptions ? onArrayChange(v as Value[]) : onChange(v as Value, index))}
          optionFilterProp="label"
          options={options}
          placeholder={placeholder}
          showSearch
          size={size}
          style={{ width: width ?? 200 }}
          value={multipleOptions ? values?.map((v) => String(v)) : value?.toString()}
        />
      );
    }

    return (
      <Select
        disabled={readonly}
        key={index}
        mode={multipleOptions ? 'multiple' : undefined}
        onChange={(v) => (multipleOptions ? onArrayChange(v as Value[]) : onChange(v as Value, index))}
        optionFilterProp="label"
        options={options}
        placeholder={placeholder}
        showSearch
        size={size}
        style={{ width: width ?? 200 }}
        value={multipleOptions ? values : value}
      />
    );
  }

  if (pattern === FilterPatternName.Contains) {
    return stringInput;
  }

  switch (type) {
    case GraphQLScalarType.ISO8601:
    case GraphQLScalarType.ISO8601Date:
      return (
        <DatePicker
          key={index}
          index={index}
          onChange={onChange}
          readonly={readonly}
          value={value}
          withKeywords={pattern !== FilterPatternName.Between}
          withTime={type === GraphQLScalarType.ISO8601}
        />
      );
    case GraphQLScalarType.Int:
    case GraphQLScalarType.BigInt:
    case GraphQLScalarType.Float:
      return (
        <InputNumber
          disabled={readonly}
          key={index}
          placeholder={placeholder}
          prefix={prefix}
          size={size}
          parser={numberParser}
          value={value}
          style={{ width: width ?? 'auto' }}
          onChange={(v) => onChange(v || undefined, index)}
        />
      );
    default:
      return stringInput;
  }
};

interface Props {
  multipleOptions?: boolean;
  options?: { label: string; value: string }[];
  pattern: FilterPatternName | CustomFilterPattern;
  placeholder?: string;
  prefix?: React.ReactNode;
  readonly?: boolean;
  size?: SizeType;
  type: GraphQLScalarType;
  values?: Value[];
  width?: number;
  onChange?: (values: Value[]) => void;
  optionsType?: OptionsType;
}

const ValueInputs = observer<Props>(
  ({
    multipleOptions,
    options,
    pattern,
    placeholder,
    prefix,
    readonly,
    size,
    type,
    values,
    width,
    optionsType,
    onChange,
  }) => {
    const alteredValues = values ?? [];
    const currentPattern = pattern && allFilterPatterns[pattern];

    const handleValueChange = (value: Value, index: number) => {
      const newValues = [...alteredValues.slice(0, index), value, ...alteredValues.slice(index + 1)];

      if (onChange != null) onChange(newValues);
    };

    const handleArrayChange = (values: Value[]) => {
      if (onChange != null) onChange(values);
    };

    const attributeInputs = map(Array(currentPattern?.arity ?? 0), (_, index) =>
      attributeInput({
        type,
        index,
        readonly,
        options,
        multipleOptions,
        pattern,
        placeholder,
        prefix,
        size,
        optionsType,
        onChange: handleValueChange,
        onArrayChange: handleArrayChange,
        value: alteredValues.length - 1 >= index ? alteredValues[index] : undefined,
        values,
        width,
      }),
    );

    return <ValueInputsContainer>{attributeInputs}</ValueInputsContainer>;
  },
);

export default ValueInputs;
