export enum ErpPermissionGroup {
  BankPayments = 'bank_payments',
  BillingAccounts = 'billing_accounts',
  Claims = 'claims',
  DatevInteractions = 'datev_interactions',
  IntacctInteractions = 'intacct_interactions',
  Deals = 'deals',
  IncomingInvoices = 'incoming_invoices',
  Jobs = 'jobs',
  Looker = 'looker',
  EmailMessages = 'email_messages',
  Messages = 'messages',
  MessageFilters = 'message_filters',
  MessageSubscriptions = 'message_subscriptions',
  OrderConfirmations = 'order_confirmations',
  OrganizationBalances = 'organization_balances',
  Organizations = 'organizations',
  OutcomingInvoices = 'outcoming_invoices',
  LogisticInvoices = 'logistic_invoices',
  PartnerBalances = 'partner_balances',
  Partners = 'partners',
  Payments = 'payments',
  Payouts = 'payouts',
  Persons = 'persons',
  ProductionOrders = 'production_orders',
  QualityControls = 'quality_controls',
  Quotes = 'quotes',
  Coupons = 'coupons',
  ShippingAddresses = 'shipping_addresses',
  Shippings = 'shippings',
  DealPartHsCodes = 'deal_part_hs_codes',
  Storages = 'storages',
  Tasks = 'tasks',
  UserManagement = 'user_management',
  Teams = 'teams',
  Workspaces = 'workspaces',
  Dashboards = 'dashboards',
  RfqOffers = 'rfq_offers',
  Rfqs = 'rfqs',
  DrawingsOrdersList = 'drawings_orders_list',
  DrawingsQuotesList = 'drawings_quotes_list',
  DealParts = 'deal_parts',
}

export enum BankPaymentsActions {
  BankPaymentListFilter = 'bank_payments_list_filter',
  BankPaymentListView = 'bank_payments_list_view',
  BankPaymentCreate = 'bank_payment_create',
  BankPaymentDelete = 'bank_payment_delete',
  BankPaymentImportSubmit = 'bank_payment_import_submit',
  BankPaymentUpdateBALink = 'bank_payment_update_ba_link',
  BankPaymentLinkObjects = 'bank_payment_link_objects',
  BankPaymentLinkComplete = 'bank_payment_api_complete',
  BankPaymentLinkTrack = 'bank_payment_api_track',
  BankPaymentTransfer = 'payment_transfer',
}

export enum BillingAccountsActions {
  BillingAccountsListFilter = 'ba_list_filter',
  BillingAccountsListView = 'ba_list_view',
  UpdatePaymentTermsDATEV = 'update_payment_terms_datev',
  UpdatePaymentTermsIntacct = 'update_payment_terms_intacct',
}

export enum ClaimsActions {
  ClaimsListFilter = 'claims_list_filter',
  ClaimsListView = 'claims_list_view',
  Create = 'create',
  View = 'view',
  Update = 'update',
}

export enum DatevInteractionsActions {
  DatevPagesView = 'datev_pages_view',
  DatevPagesSubmit = 'datev_pages_submit',
}

export enum EmailMessagesActions {
  EmailMessagesListFilter = 'email_messages_list_filter',
  EmailMessagesListView = 'email_messages_list_view',
  EmailMessagesListExport = 'email_messages_list_export',
}

export enum IntacctInteractionsActions {
  IntacctPagesView = 'intacct_pages_view',
  IntacctPagesSubmit = 'intacct_pages_submit',
}

export enum DealsActions {
  DealsListFilter = 'deals_list_filter',
  DealsListView = 'deals_list_view',
  UpdateInfo = 'update_info',
}

export enum IncomingInvoicesActions {
  IncomingInvoicesListFilter = 'incoming_invoices_list_filter',
  IncomingInvoicesListView = 'incoming_invoices_list_view',
  IncomingInvoicesListBulkNewBankPayment = 'incoming_invoices_list_bulk_new_bank_payment',
  Create = 'create',
  PublishDraft = 'publish_draft',
  ReturnToDraft = 'return_to_draft',
  View = 'view',
}

export enum JobsActions {
  JobsListFilter = 'jobs_list_filter',
  JobsListView = 'jobs_list_view',
  JobsListExport = 'jobs_list_export',

  JobView = 'job_view',
  JobOffersListFilter = 'job_offers_list_filter',
  JobOffersListExport = 'job_offers_list_export',

  JobCreate = 'job_create',
  JobUpdate = 'job_update',
  JobPublish = 'job_publish',
  JobCancel = 'job_cancel',
  JobForceAutomation = 'job_force_automation',

  JobOfferView = 'job_offer_view',
  JobOfferCreate = 'job_offer_create',
  JobOfferUpdate = 'job_offer_update',
  JobOfferDecision = 'job_offer_decision',
  JobOfferCancel = 'job_offer_cancel',
}

export enum MessagesActions {
  MessagesListFilter = 'messages_list_filter',
  MessagesListView = 'messages_list_view',
  MessagesListExport = 'messages_list_export',
}

export enum MessageSubscriptionsActions {
  MessageSubscriptionsListFilter = 'message_subscriptions_list_filter',
  MessageSubscriptionsListView = 'message_subscriptions_list_view',
  MessageSubscriptionsListExport = 'message_subscriptions_list_export',
  MessageSubscriptionsManagement = 'message_subscriptions_management',
}

export enum OrderConfirmationsActions {
  View = 'view',
  Create = 'create',
  Delete = 'delete',
  UpdateInfo = 'update_info',
  UpdateContent = 'update_content',
  Publish = 'publish',
}

export enum OrganizationBalancesActions {
  OrganizationBalancesListView = 'organization_balances_list_view',
  OrganizationBalancesListFilter = 'organization_balances_list_filter',
}

export enum OrganizationsActions {
  OrganizationsListFilter = 'organizations_list_filter',
  OrganizationsListView = 'organizations_list_view',
  Create = 'create',
  View = 'view',
  Update = 'update',
  UpdatePaymentTerms = 'update_payment_terms',
  AddShippingAddress = 'add_shipping_address',
}

export enum OrganizationsPermissions {
  AddShippingAddress = 'add_shipping_address',
  Create = 'create',
  OrganizationsListBulkUpdatePm = 'organizations_list_bulk_update_pm',
  OrganizationsListExport = 'organizations_list_export',
  OrganizationsListFilter = 'organizations_list_filter',
  OrganizationsListView = 'organizations_list_view',
  Update = 'update',
  UpdatePaymentTerms = 'update_payment_terms',
  UpdateVeryImportant = 'update_very_important',
  View = 'view',
}

export enum OutcomingInvoicesActions {
  Create = 'create',
  OutcomingInvoicesListFilter = 'outcoming_invoices_list_filter',
  OutcomingInvoicesListView = 'outcoming_invoices_list_view',
  PublishDraft = 'publish_draft',
  Update = 'update',
  View = 'view',
}

export enum LogisticInvoicesActions {
  Create = 'create',
  LogisticInvoicesListFilter = 'logistic_invoices_list_filter',
  LogisticInvoicesListView = 'logistic_invoices_list_view',
  PublishDraft = 'publish_draft',
  Update = 'update',
  View = 'view',
}

export enum RfqOffersActions {
  RfqOffersListExport = 'rfq_offers_list_export',
  RfqOffersListFilter = 'rfq_offers_list_filter',
  RfqOffersListView = 'rfq_offers_list_view',
}

export enum RfqsActions {
  RfqsListExport = 'rfqs_list_export',
  RfqsListFilter = 'rfqs_list_filter',
  RfqsListView = 'rfqs_list_view',
  RfqmDashboardView = 'rfqm_dashboard_view',
}

export enum DrawingsOrdersActions {
  DrawingsOrdersListExport = 'drawings_orders_list_export',
  DrawingsOrdersListFilter = 'drawings_orders_list_filter',
  DrawingsOrdersListView = 'drawings_orders_list_view',
}

export enum DrawingsQuotesActions {
  DrawingsQuotesListExport = 'drawings_quotes_list_export',
  DrawingsQuotesListFilter = 'drawings_quotes_list_filter',
  DrawingsQuotesListView = 'drawings_quotes_list_view',
}

export enum PartnerBalancesActions {
  PartnerBalancesListView = 'partner_balances_list_view',
  PartnerBalancesListFilter = 'partner_balances_list_filter',
}

export enum PartnersActions {
  PartnerView = 'view',
  PartnersListExport = 'partners_list_export',
  PartnersListFilter = 'partners_list_filter',
  PartnersListView = 'partners_list_view',
}

export enum PaymentsActions {
  PaymentsListFilter = 'payments_list_filter',
  PaymentsListView = 'payments_list_view',
  PaymentView = 'payment_view',
  PaymentCancel = 'payment_cancel',
  PaymentRequestComplete = 'payment_request_complete',
  RefundCancel = 'refund_cancel',
  RefundRequestManualComplete = 'refund_request_manual_complete',
  RefundRequestApiComplete = 'refund_request_api_complete',
}

export enum PayoutsActions {
  PayoutsListFilter = 'payouts_list_filter',
  PayoutsListView = 'payouts_list_view',
  PayoutsListBulkNewBankPayment = 'payouts_list_bulk_new_bank_payment',
  PayoutRequestComplete = 'payout_request_complete',
  PayoutView = 'payout_view',
}

export enum PayoutsPermissions {
  PayoutRequestComplete = 'payout_request_complete',
  RefundRequestComplete = 'refund_request_complete',
}

export enum PersonsActions {
  PersonsListFilter = 'persons_list_filter',
  PersonsListView = 'persons_list_view',
  Create = 'create',
  View = 'view',
  Update = 'update',
  Emulate = 'emulate',
  ModifySA = 'modify_sa',
  ApplySA = 'apply_sa',
}

export enum ProductionOrdersActions {
  ProductionOrdersListExport = 'po_list_export',
  ProductionOrdersListFilter = 'po_list_filter',
  ProductionOrdersListView = 'po_list_view',

  ProductionScheduleItemsListExport = 'production_schedule_items_list_export',
  ProductionScheduleItemsListFilter = 'production_schedule_items_list_filter',
  ProductionScheduleItemsListView = 'production_schedule_items_list_view',

  Create = 'po_create',
  View = 'po_view',
  Update = 'po_update',
  UpdateXometryVatNumber = 'update_xometry_vat_number',
  Cancel = 'po_cancel',
}

export enum ProductionOrdersPermissions {
  Cancel = 'po_cancel',
  Create = 'po_create',
  ProductionOrdersListExport = 'po_list_export',
  ProductionOrdersListFilter = 'po_list_filter',
  ProductionOrdersListView = 'po_list_view',
  ShippingProcessingInstructionsEdit = 'shipping_processing_instructions_edit',
  Update = 'po_update',
  UpdateXometryVatNumber = 'update_xometry_vat_number',
  View = 'po_view',
}

export enum JobsPermissions {
  ShippingProcessingInstructionsEdit = 'job_offers_shipping_processing_instructions_edit',
}

export enum QualityControlsActions {
  QualityControlsListFilter = 'qc_list_filter',
  QualityControlsListView = 'qc_list_view',
  Create = 'create',
  View = 'view',
  Update = 'update',
  Cancel = 'cancel',
}

export enum QuotesActions {
  QuotesListFilter = 'quotes_list_filter',
  QuotesListView = 'quotes_list_view',
  UpdateOrdered = 'update_ordered',
  UpdateNotOrdered = 'update_not_ordered',
}

export enum CouponsActions {
  create = 'coupons_create',
  listFilter = 'coupons_list_filter',
  listView = 'coupons_list_view',
}

export enum QuotesPermissions {
  ResetPriceValidityDate = 'reset_price_validity_date',
  UpdatePartPrices = 'update_part_prices',
  UpdatePartPricesNpg = 'update_part_prices_npg',
}

export enum ShippingAddressesActions {
  ShippingAddressesListFilter = 'sa_list_filter',
  ShippingAddressesListView = 'sa_list_view',
}

export enum DealPartHsCodesActions {
  DealPartHsCodesListView = 'hs_codes_list_view',
  DealPartHsCodesListFilter = 'hs_codes_list_filter',
}

export enum ShippingsActions {
  ShippingsListFilter = 'shippings_list_filter',
  ShippingsListView = 'shippings_list_view',
  CreateClone = 'create_clone',
  View = 'view',
  Update = 'update',
  Cancel = 'cancel',
}

export enum ShippingsPermissions {
  OverrideShippingKeyParameters = 'override_shipping_key_parameters',
  IgnoreInstructions = 'ignore_instructions',
}

export enum StoragesActions {
  StoragesListFilter = 'storages_list_filter',
  StoragesListView = 'storages_list_view',
  Create = 'storage_create',
  View = 'storage_view',
  Update = 'storage_update',
  Cancel = 'storage_cancel',
}

interface IntacctInteractionsGroupAndAction {
  group: ErpPermissionGroup.IntacctInteractions;
  action: IntacctInteractionsActions;
}

export enum TasksActions {
  TasksListFilter = 'tasks_list_filter',
  TasksListView = 'tasks_list_view',
  TasksListExport = 'tasks_list_export',
}

export enum UserManagementActions {
  UsersListFilter = 'users_list_filter',
  UsersListView = 'users_list_view',
  ReadPermissions = 'read_permissions',
  UpdatePermissions = 'update_permissions',
  ReadRoles = 'read_roles',
  UpdateRoles = 'update_roles',
  UpdateRolePermissions = 'update_role_permissions',
  UpdateProfile = 'update_profile',
  UpdateMessengerFilters = 'update_users_message_filters',
  UpdateOwnMessengerFilters = 'update_own_message_filters',
  MessageFiltersListView = 'message_filters_list_view',
  MessageFiltersListUpdate = 'message_filters_list_update',
  CreateSubstitution = 'create_substitution',
  DeleteSubstitution = 'delete_substitution',
  UpdateSubstitution = 'update_substitution',
}

export enum UserManagementPermissions {
  UpdatePmAssignmentSettings = 'update_pm_assignment_settings',
}

export enum TeamsActions {
  EditUsers = 'edit_users',
  ListView = 'teams_list_view',
  Create = 'create',
}

export enum BillingAccountPermissions {
  RequestCreditLimit = 'request_credit_limit',
  PaymentTermsUpdateOverThreshold = 'payment_terms_update_over_threshold',
}

export enum WorkspacesActions {
  CreatePresets = 'create_presets',
  UpdatePresets = 'update_presets',
}

export enum DashboardsActions {
  AllOrders = 'pm_load',
}

export enum DealPartsActions {
  UpdateImAttributes = 'update_im_attributes',
}

interface BankPaymentsGroupAndAction {
  group: ErpPermissionGroup.BankPayments;
  action: BankPaymentsActions;
}

interface BillingAccountsGroupAndAction {
  group: ErpPermissionGroup.BillingAccounts;
  action: BillingAccountsActions;
}

interface ClaimsGroupAndAction {
  group: ErpPermissionGroup.Claims;
  action: ClaimsActions;
}

interface CouponsGroupAndAction {
  group: ErpPermissionGroup.Coupons;
  action: CouponsActions;
}

interface DatevInteractionsGroupAndAction {
  group: ErpPermissionGroup.DatevInteractions;
  action: DatevInteractionsActions;
}

interface IntacctInteractionsGroupAndAction {
  group: ErpPermissionGroup.IntacctInteractions;
  action: IntacctInteractionsActions;
}

interface DealsGroupAndAction {
  group: ErpPermissionGroup.Deals;
  action: DealsActions;
}

interface IncomingInvoicesGroupAndAction {
  group: ErpPermissionGroup.IncomingInvoices;
  action: IncomingInvoicesActions;
}

interface JobsGroupAndAction {
  group: ErpPermissionGroup.Jobs;
  action: JobsActions;
}

interface MessagesGroupAndAction {
  group: ErpPermissionGroup.Messages;
  action: MessagesActions;
}

interface MessageSubscriptionsGroupAndAction {
  group: ErpPermissionGroup.MessageSubscriptions;
  action: MessageSubscriptionsActions;
}

interface OrderConfirmationsGroupAndAction {
  group: ErpPermissionGroup.OrderConfirmations;
  action: OrderConfirmationsActions;
}

interface OrganizationBalancesGroupAndAction {
  group: ErpPermissionGroup.OrganizationBalances;
  action: OrganizationBalancesActions;
}

interface OrganizationsGroupAndAction {
  group: ErpPermissionGroup.Organizations;
  action: OrganizationsActions;
}

interface OutcomingInvoicesGroupAndAction {
  group: ErpPermissionGroup.OutcomingInvoices;
  action: OutcomingInvoicesActions;
}

interface PartnerBalancesGroupAndAction {
  group: ErpPermissionGroup.PartnerBalances;
  action: PartnerBalancesActions;
}

interface PartnersGroupAndAction {
  group: ErpPermissionGroup.Partners;
  action: PartnersActions;
}

interface PaymentsGroupAndAction {
  group: ErpPermissionGroup.Payments;
  action: PaymentsActions;
}

interface PayoutsGroupAndAction {
  group: ErpPermissionGroup.Payouts;
  action: PayoutsActions;
}

interface PersonsGroupAndAction {
  group: ErpPermissionGroup.Persons;
  action: PersonsActions;
}

interface ProductionOrdersGroupAndAction {
  group: ErpPermissionGroup.ProductionOrders;
  action: ProductionOrdersActions;
}

interface QualityControlsGroupAndAction {
  group: ErpPermissionGroup.QualityControls;
  action: QualityControlsActions;
}

interface QuotesGroupAndAction {
  group: ErpPermissionGroup.Quotes;
  action: QuotesActions;
}

interface ShippingAddressesGroupAndAction {
  group: ErpPermissionGroup.ShippingAddresses;
  action: ShippingAddressesActions;
}

interface ShippingsGroupAndAction {
  group: ErpPermissionGroup.Shippings;
  action: ShippingsActions;
}

interface StoragesGroupAndAction {
  group: ErpPermissionGroup.Storages;
  action: StoragesActions;
}

interface TasksGroupAndAction {
  group: ErpPermissionGroup.Tasks;
  action: TasksActions;
}

interface UserManagementGroupAndAction {
  group: ErpPermissionGroup.UserManagement;
  action: UserManagementActions;
}

interface WorkspacesGroupAndAction {
  group: ErpPermissionGroup.Workspaces;
  action: WorkspacesActions;
}

interface TeamsGroupAndAction {
  group: ErpPermissionGroup.Teams;
  action: TeamsActions;
}

interface DealPartsGroupAndAction {
  group: ErpPermissionGroup.DealParts;
  action: DealPartsActions;
}

export type GroupAndAction =
  | BankPaymentsGroupAndAction
  | BillingAccountsGroupAndAction
  | ClaimsGroupAndAction
  | CouponsGroupAndAction
  | DatevInteractionsGroupAndAction
  | IntacctInteractionsGroupAndAction
  | DealsGroupAndAction
  | IncomingInvoicesGroupAndAction
  | JobsGroupAndAction
  | MessagesGroupAndAction
  | MessageSubscriptionsGroupAndAction
  | OrderConfirmationsGroupAndAction
  | OrganizationBalancesGroupAndAction
  | OrganizationsGroupAndAction
  | OutcomingInvoicesGroupAndAction
  | PartnerBalancesGroupAndAction
  | PartnersGroupAndAction
  | PaymentsGroupAndAction
  | PayoutsGroupAndAction
  | PersonsGroupAndAction
  | ProductionOrdersGroupAndAction
  | QualityControlsGroupAndAction
  | QuotesGroupAndAction
  | ShippingAddressesGroupAndAction
  | ShippingsGroupAndAction
  | StoragesGroupAndAction
  | TasksGroupAndAction
  | UserManagementGroupAndAction
  | WorkspacesGroupAndAction
  | TeamsGroupAndAction
  | DealPartsGroupAndAction;
