import { ObjectType } from 'interfaces';
import { IServerDealLot } from 'interfaces/stores/Shipping';
import { routesApi, TypeRouteId } from 'routes/api';
import api, { camelizeKeys } from 'utils/axios';

interface Response {
  payload: IServerDealLot[];
}

export const getShippingSourcePositions = async (
  dealId: TypeRouteId,
  sourceType: ObjectType,
  sourceId: TypeRouteId,
) => {
  const response = await api.get<Response>(routesApi.shippingsSourcePositionsPath(dealId, sourceType, sourceId));

  return camelizeKeys(response.data.payload);
};
