export enum EFeatureFlags {
  ERP_COMMENTS = 'erp_comments', // https://xometry.atlassian.net/browse/XEMSG-821
  REPLACE_CRM_INFO_ENABLED = 'erp_replace_crm_info_enabled',
  YURTICI_ORDER = 'erp_yurtici_order',
  ERP_TEMPLATES_MODAL_EMAIL_CUSTOMER = 'erp_templates_modal_email_customer', // https://xometry.atlassian.net/browse/XEMSG-1081

  /**
   * https://xometry.atlassian.net/browse/XEMSG-1352
   */
  ERP_TEMPLATES_MODAL_EMAIL_ENQUIRY = 'erp_templates_modal_email_enquiry',
  ERP_TEMPLATES_MODAL_EMAIL_OC = 'erp_templates_modal_email_oc',
  ERP_TEMPLATES_MODAL_EMAIL_OI = 'erp_templates_modal_email_oi',
  ERP_TEMPLATES_MODAL_EMAIL_II = 'erp_templates_modal_email_ii',
  ERP_TEMPLATES_MODAL_EMAIL_QUALITY_CONTROL = 'erp_templates_modal_email_quality_control',
  ERP_TEMPLATES_MODAL_EMAIL_CLAIM = 'erp_templates_modal_email_claim',
  ERP_TEMPLATES_MODAL_EMAIL_PAYMENT = 'erp_templates_modal_email_payment',

  /**
   * Custom messenger templates.
   *
   * https://xometry.atlassian.net/browse/XEMSG-1256
   * https://xometry.atlassian.net/browse/XEMSG-1017
   */
  ERP_MESSENGER_TEMPLATES = 'erp_messenger_templates',

  COMPLIANCE_CERTIFICATES = 'erp_compliance_certificates',

  ERP_LOGISTIC_COST_EST = 'erp_logistic_cost_est',

  XMAS_ENABLED = 'erp_xmas_enabled',

  /**
   * https://xometry.atlassian.net/browse/XETW-1579
   */
  CUSTOMER_OBJECTS_ENABLED = 'erp_customer_objects_enabled',

  /**
   * Deal Document Object.
   *
   * https://xometry.atlassian.net/browse/XEPE-1029
   */
  DEAL_DOCUMENTS_ENABLED = 'erp_deal_documents',
}
