/**
 * Entry points for MESSENGER_OPENED event.
 */
export enum MessengerEntryPoint {
  DEAL_HEADER = 'DealHeader',
  DOCUMENT_TITLE = 'DocumentTitle',
  FOOTER = 'Footer',
  HEADER_SUBSCRIPTIONS = 'HeaderSubscriptions',
  HEADER_TASKS = 'HeaderTasks',
  LINK = 'Link',
  PART_TITLE = 'PartTitle',
  SIDEBAR = 'Sidebar',
  RFQ_PROCESS = 'RfqProcess',
  WORKSPACES_ENQUIRY = 'WorkspacesEnquiry',
}

/**
 * Places for SUBSCRIBED event.
 */
export enum SubscribePlace {
  SIDEBAR = 'Sidebar',
}

/**
 * Keys for amplitude event attributes
 */
export enum AmplitudeAttributeKey {
  ENTRY_POINT = 'EntryPoint',
  PLACE = 'Place',
  SIDE = 'Side',
}

export enum AmplitudeMessengerEvent {
  MESSENGER_OPENED = 'MessengerOpened',
  MESSENGER_CLOSED = 'MessengerClosed',
  MESSENGER_SIDE_SWAPPED = 'MessengerSideSwapped',
  SUBSCRIBE = 'Subscribe',
  UNSUBSCRIBE = 'Unsubscribe',
}

export enum AmplitudeErpDealEvent {
  SORT_SIDEBAR_DEAL_OBJECTS = 'SortSidebarDealObjects',
  DEAL_LEFT_HEADER_ACTION = 'DealLeftHeaderAction',
}

export const SEND_EMAIL_BUTTON_CLICK_EVENT = 'SendEmailButtonClick';

export enum AmplitudeErpObjectsFilterEvent {
  FILTER_PRESET_SELECT = 'ErpObjectsFilterPresetSelect',
  FILTER_OBJECT_CHANGE = 'ErpObjectsFilterObjectChang',
  FILTER_RESET = 'ErpObjectsFilterReset',
  FILTER_APPLY = 'ErpObjectsFilterApply',
}
