import {
  OutcomingInvoicesCashCollectionEnum,
  OutcomingInvoicesCreditNoteReasonEnumType,
  XometryVatCountryEnum,
} from '__generated__/types';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';

const returnInvoiceByRegion: Record<Regions, string> = {
  [Regions.EU]: 'Credit Note',
  [Regions.INDIA]: 'Credit Note',
  [Regions.TR]: 'Return Invoice Request',
  [Regions.UK]: 'Credit Note',
};

export const RETURN_INVOICE_REQUEST = returnInvoiceByRegion[CURRENT_REGION];

export const creditNoteReasons = {
  [OutcomingInvoicesCreditNoteReasonEnumType.Claim]: 'Claim',
  [OutcomingInvoicesCreditNoteReasonEnumType.WrongPriceOrQuantity]: 'Wrong Price or Quantity',
  [OutcomingInvoicesCreditNoteReasonEnumType.WrongBa]: 'Wrong BA (reissue)',
};

export const creditNoteReasonOptions = Object.values(OutcomingInvoicesCreditNoteReasonEnumType).map((reason) => ({
  label: creditNoteReasons[reason],
  value: reason,
}));

export const cashCollectionStatuses = {
  [OutcomingInvoicesCashCollectionEnum.ProductionManager]: 'Production Manager',
  [OutcomingInvoicesCashCollectionEnum.Kam]: 'KAM',
  [OutcomingInvoicesCashCollectionEnum.CreditReform]: 'Credit Reform',
  [OutcomingInvoicesCashCollectionEnum.CreditReformPaid]: 'Credit Reform Paid',
  [OutcomingInvoicesCashCollectionEnum.CreditReformFailed]: 'Credit Reform Failed',
  [OutcomingInvoicesCashCollectionEnum.ShipAndHold]: 'Ship And Hold',
  [OutcomingInvoicesCashCollectionEnum.Insolvency]: 'Insolvency',
};

export const cashCollectionStatusesOptions = Object.entries(cashCollectionStatuses).map(([value, label]) => ({
  label,
  value,
}));

export const remarks = [
  'Intra-Community triangulation operation acc. to Sec. 25b German VAT Act. VAT is owed by the recipient of the supply.',
  'VAT exempt export delivery. Steuerfreie Ausfuhrlieferung.',
  'VAT exempt intra-community supply of goods',
  'VAT due by the customer pursuant article 283-1 al. 2 of the French tax code',
  'Reverse Charge',
];

export const remarkOptions = remarks.map((remark) => ({
  label: remark,
  value: remark,
}));

export const xometryVatCountryOptions = Object.values(XometryVatCountryEnum).map((country) => ({
  label: country,
  value: country,
}));
