import { PartTypeEnum } from '__generated__/types';
import { DrawingsReworkStatus } from 'collections/drawingsReworksStatus';
import { HSCodeState } from 'collections/part';
import { Tag } from 'interfaces';

import { IAttachedFile } from './AttachedFilesStore';
import { Autocomplete } from './Autocompletes/UsersPipedriveStore';

export enum PartsSortOptions {
  ID_ASC = 'Part ID (asc)',
  ID_DESC = 'Part ID (desc)',
  POSITION_ASC = 'Position (asc)',
  POSITION_DESC = 'Position (desc)',
  CREATION_ASC = 'Creation date (asc)',
  CREATION_DESC = 'Creation date (desc)',
}

export enum AuditState {
  New = 'new',
  NeedInfo = 'need_info',
  ReviewedOk = 'reviewed_ok',
  AutomaticSkipped = 'automatic_skipped',
  ReviewedHighRisk = 'reviewed_high_risk',
  ReviewedReject = 'reviewed_reject',
}

export enum PartClaimState {
  Closed = 'closed',
  Active = 'active',
}

export enum PartProcess {
  '3D' = '3d',
  CNC = 'cnc',
  SheetMetal = 'sheet_metal',
}

export enum PartDrawingsState {
  NoFiles = 'no_files',
  Rework = 'rework',
  Cleaned = 'cleaned',
  NotCleaned = 'not_cleaned',
  PartiallyCleaned = 'partially_cleaned',
}

export enum PartRelatedObjectType {
  J = 'J',
  CL = 'CL',
  OC = 'OC',
  PO = 'PO',
  SU = 'SU',
  SH = 'SH',
  DL = 'DL',
  R = 'R',
  G = 'G',
  LR = 'LR',
  LG = 'LG',
  OI = 'Out.Inv',
  OICN = 'Out.CN',
  QC = 'QC',
  PR = 'PR',
}

export interface PartDimensions {
  d?: number;
  x: number;
  y?: number;
  z?: number;
}

export interface PartRelatedObject {
  id: number;
  type: PartRelatedObjectType;
  state: string;
  quantity: number;
  providerPrice?: number;
  customerPrice?: number;
  customerPriceCurrency?: string;
  providerPriceCurrency?: string;
  meta?: {
    direction?: string;
  };
}

export enum FileUploadTypes {
  Merge = 'merge',
  Replace = 'replace',
}

export interface PartEditFormValues {
  position: number;
  name: string;
  material: string;
  finish: string;
  weightKg: number;
  dimensions: PartDimensions;

  comments: string;
  customerNote: string;
  productionRemark: string;

  isNoQuote: boolean;
  isExpress: boolean;
  isMpNeeded: boolean;
  mpNeeded?: MeasurementProtocols;
  isPreQuoted: boolean;
  isSamplesNeeded: boolean;
  samplesQuantity?: number;
  samplesComment?: string;

  drawingsReworkedBy?: string;
  drawingsReworkStatus?: DrawingsReworkStatus;
  auditUpdatedBy?: string;
  auditUpdatedAt: string | null;
  drawingReworkTask?: string;
  auditComment?: string;
  auditState?: string;

  preQuotedBy?: number;
  preQuotedByOption?: { label: string; value: number };
  preQuotedComment?: string;
  preQuotedTicket?: string;
  appendFilesWith: FileUploadTypes;
  isPartVerChange: boolean;
  versionDesc: string;

  productionMethodFeaturesTagsOption: Autocomplete[];
  productionMethodsTagsOption: Autocomplete[];
  materialsTagsOption: Autocomplete[];
  productionRisksTagsOption: Autocomplete[];
}

export interface PartDrawingsFormValues {
  isDrawingsReworkNotDone: boolean;
  drawingReworkTask?: string;
  drawingsReworkedBy?: string;
  drawingsReworkStatus: DrawingsReworkStatus;
  isPartVerChange: boolean;
  versionDesc: string;
}

export interface PartAuditFormValues {
  comments: string;
  auditComment?: string;
  auditState: AuditState;
  drawingsReworkedBy?: string;
  drawingsReworkStatus: DrawingsReworkStatus;
  auditUpdatedBy?: string;
  drawingReworkTask?: string;
  productionMethodsTags?: number[];
  productionMethodsTagsOption: Autocomplete<number>[];
  productionMethodFeaturesTags?: number[];
  productionMethodFeaturesTagsOption: Autocomplete<number>[];
  materialsTags?: number[];
  materialsTagsOption: Autocomplete<number>[];
  productionRisksTags?: number[];
  productionRisksTagsOption: Autocomplete<number>[];
}

export type PartFormValues = PartEditFormValues & PartDrawingsFormValues & PartAuditFormValues;

export enum MeasurementProtocols {
  Standard = 'standard',
  Fair = 'fair',
  CMM = 'cmm',
}

export interface NewPart {
  _uid: string;
  dealId: number;
  name: string;
  originalName: string;
  material?: string;
  postProcessing?: string;
  quantity: number;
  customerNote?: string;
  fileIds: number[];
  partType?: PartTypeEnum;
  returnRequired?: boolean;
}

export interface PartCustomerLot {
  id: number;
  quantity: number;
  salePrice?: number;
  costForecast?: number;
}

export interface Part {
  id: number;
  createdAt: string;
  position?: number;
  name: string;

  comments: string;
  productionRemark: string;
  customerNote: string;

  process: PartProcess;
  weightKg: number;
  dimensions?: PartDimensions;
  material: string;
  postProcessing: string;

  tolerance: number;
  surfaceRoughness: number;
  color: string;
  hsCode?: string;
  hsCodeName?: string;
  hsCodePurpose?: string;
  hsCodeState: HSCodeState;
  hsCodeConfirmedBy?: { label: string; email: string; id: number };
  hsCodeConfirmedAt: string | null;

  tags: Tag[];

  isSamplesNeeded: boolean;
  samplesQuantity: number;
  samplesComment?: string;
  isMeasurementProtocolNeeded?: MeasurementProtocols;
  isNoQuote: boolean;
  isExpress: boolean;
  customerLots: PartCustomerLot[];

  relatedObjects: PartRelatedObject[];

  isOrdered: boolean;
  quantity: number | null;
  customerPrice: number;
  providerPrice: number;
  enquiryPartId: number;

  isPreQuoted: boolean;
  preQuotedBy?: {
    value: number;
    label: string;
  };
  preQuotedComment: string;

  auditComment?: string;
  drawingReworkTask?: string;
  drawingsReworkStatus: DrawingsReworkStatus;
  drawingsReworkedAt?: string;
  drawingsReworkedBy?: { label: string; email: string };
  auditUpdatedBy?: { label: string; email: string };
  auditUpdatedAt: string | null;

  auditState: AuditState;
  claimState?: PartClaimState;
  drawingsState: PartDrawingsState;

  attachmentFiles: IAttachedFile[];

  versionsCount: number;

  selected?: boolean;

  returnRequired?: boolean;
  partType?: PartTypeEnum;
  customerObjectPrice: {
    amountCents: string;
    currencyCode: string;
    amount: string;
  } | null;
}

export enum ZipFilesType {
  ORIGINAL = 'original',
  CLEANED = 'cleaned',
}
