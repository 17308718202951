import { getAccountingIdName } from 'config/regions/utils';
import { ENV_API_ENDPOINT, ENV_BASE_URL } from 'constants/env';

const apiEndpoint = ENV_API_ENDPOINT;

const accountingIdName = getAccountingIdName().toLowerCase();

export type TypeRouteId = string | number;
const apiBase = `${apiEndpoint ?? ''}/v2/oms`;
const messengerApiBase = `${apiEndpoint ?? ''}/v2/messenger`;

const orgsPath = () => `${apiBase}/organizations`;
const orgPath = (id: TypeRouteId) => `${orgsPath()}/${id.toString()}`;

const dealsPath = () => `${apiBase}/deals`;
const dealPath = (id: TypeRouteId) => `${dealsPath()}/${id.toString()}`;

const bankPaymentsSendToJpmPath = () => `${apiBase}/bank_payments/send_to_jpm`;
const uploadBulkIncomingInvoices = () => `${apiBase}/incoming_invoices/bulk_download_zip`;
const uploadBulkOutcomingInvoices = () => `${apiBase}/outcoming_invoices/bulk_download_zip`;

const authPath = () => `${apiBase}/auth`;
const versionsPath = () => `${apiEndpoint ?? ''}/v2/release_version`;

const asyncJobsPath = () => `${apiBase}/async_jobs`;
const asyncJobPath = (id: string) => `${asyncJobsPath()}/${id}`;

const outcomingInvoicePath = (id: string) => `${apiBase}/outcoming_invoices/${id}`;
const outcomingInvoicesUploadMicroJumpDocumentPath = (id: string) =>
  `${outcomingInvoicePath(id)}/upload_microjump_document`;

const dealEmailTemplates = (id: string) => `${dealPath(id)}/email_templates`;
const dealSendEmail = (id: string) => `${dealPath(id)}/send_email`;
const dealSendDirectEmail = (id: string) => `${dealPath(id)}/send_direct_email`;
const dealProgressEmailTemplatesPath = (id: TypeRouteId) => `${dealPath(id)}/progress_email_templates`;

const templatePreview = (templateId: TypeRouteId) => `${messengerApiBase}/template-preview/${templateId}`;

const ssoLogin = (transport: string) =>
  `${apiEndpoint ?? ''}/sso/${transport}/login?redirect_url=${window.location.origin}${
    ENV_BASE_URL.length > 0 && ENV_BASE_URL[ENV_BASE_URL.length - 1] === '/'
      ? ENV_BASE_URL.substr(0, ENV_BASE_URL.length - 1)
      : ENV_BASE_URL
  }`;

const providerPath = (id: TypeRouteId) => `${apiBase}/providers/${id}`;
const providerEmailTemplates = (id: TypeRouteId) => `${providerPath(id)}/email_templates`;
const providerRatingInfoPdf = (id: TypeRouteId) => `${providerPath(id)}/rating_info_pdf`;
const providerSendEmail = (id: TypeRouteId) => `${providerPath(id)}/send_email`;
const providerSendDirectEmail = (id: TypeRouteId) => `${providerPath(id)}/send_direct_email`;

const dealFetchFiles = (id: string) => `${dealPath(id)}/files`;
const dealFetchUnlinkedFilesPath = (id: TypeRouteId) => `${dealPath(id)}/unlinked_files`;
const dealFetchPipedriveFiles = (id: TypeRouteId) => `${dealPath(id)}/pipedrive_files`;
const dealUploadPdFiles = (id: string) => `${dealPath(id)}/upload_pipedrive_files`;
const dealUploadUserFiles = (id: string) => `${dealPath(id)}/upload_user_files`;
const dealDestroyFiles = (id: string) => `${dealPath(id)}/destroy_user_files`;
const dealUploadFile = (id: string) => `${dealPath(id)}/upload_attached_files`;
const dealRedirectUrl = (id: TypeRouteId) => `${dealPath(id)}/redirect_url`;
const dealEnquiryPath = (id: TypeRouteId, enquiryId: TypeRouteId) => `${dealPath(id)}/enquiry?enquiry_id=${enquiryId}`;
const enquiriesPath = () => `${apiBase}/enquiries`;
const enquiryPath = (id: TypeRouteId) => `${apiBase}/enquiries/${id}`;
const enquiryPartsPath = (enquiryId: TypeRouteId, partId: TypeRouteId) => `${enquiryPath(enquiryId)}/parts/${partId}`;
const enquiryPartAttachmentsPath = (enquiryId: TypeRouteId, partId: TypeRouteId) =>
  `${enquiryPath(enquiryId)}/parts/${partId}/attachments`;
const enquiryStatePath = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/state`;
const enquiryPdfPath = (id: TypeRouteId, partIds: TypeRouteId[], type?: string) =>
  `${apiBase}/enquiries/${id}/download.pdf?part_ids=${partIds.join(',')}&type=${type || ''}`;
const enquiryRfqPath = (id: TypeRouteId, partIds: TypeRouteId[], format: 'xlsx' | 'pdf') =>
  `${apiBase}/enquiries/${id}/download_rfq?part_ids=${partIds.join('|')}&export_type=${format}`;
const enquiryDownloadRFQMoldingPath = (id: TypeRouteId, partIds: TypeRouteId[]) =>
  `${apiBase}/enquiries/${id}/download_rfq_molding_template?part_ids=${partIds.join('|')}&export_type=xlsx`;
const enquiryCloneQuote = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/clone`;
const enquiryCancelPath = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/toggle_cancel`;
const enquiryRecalculateSummaryPath = (id: TypeRouteId) => `${enquiryPath(id)}/recalculate_summary`;
const enquiryBulkCloneParts = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/clone_parts`;
const enquiryBulkUpdateStandard = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_standard`;
const enquiryBulkUpdateExpress = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_express`;
const enquiryBulkUpdateFeatures = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_features`;
const enquiryBulkUpdateRfq = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_rfq`;
const enquiryBulkUpdatePreQuote = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_pre_quote`;
const enquiryBulkUpdateTags = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_tags`;
const enquiryBulkUpdateManualPriceExpiration = (id: TypeRouteId) =>
  `${apiBase}/enquiries/${id}/parts/bulk_actions/reset_manual_price_expiration`;
const enquiryBulkUpdateIQEPrice = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/parts/bulk_actions/recalculate`;
const enquiryCheckPromotionPath = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/promotions/check_coupon`;
// const enquiryApplyPromotionPath = (id: TypeRouteId) =>
//   `${apiBase}/enquiries/${id}/promotions/apply_coupon`;
// const enquiryDestroyPromotionPath = (id: TypeRouteId) =>
//   `${apiBase}/enquiries/${id}/promotions/destroy_coupon`;
const enquiryUpdatePropmotions = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_promotion_setting`;
const enquiryUpdateCurrency = (id: TypeRouteId) => `${apiBase}/enquiries/${id}/update_currency`;
const enquiryFeaturesPath = () => `${apiBase}/enquiries/features`;
const enquiryMaterialsPath = (materialTypeId: number) =>
  `${apiBase}/enquiries/materials?material_type_id=${materialTypeId}`;
const enquiryPartsFileUploadPath = (enquiryId: number, partId: number) =>
  `${enquiryPartsPath(enquiryId, partId)}/upload_additional_file`;
const enquiryRestorePartPath = (enquiryId: number, partId: number) => `${enquiryPartsPath(enquiryId, partId)}/restore`;
const enquiryPartUpdateManualPriceExpiration = (id: TypeRouteId, partId: TypeRouteId) =>
  `${enquiryPartsPath(id, partId)}/reset_manual_price_expiration`;
const enquiryCreateAdditionalServicePath = (enquiryId: number) =>
  `${apiBase}/enquiries/${enquiryId}/additional_services`;
const enquiryUpdateVatPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/update_vat_rate`;
const enquiryUpdateAdditionalServicePath = (enquiryId: number, serviceId: number) =>
  `${apiBase}/enquiries/${enquiryId}/additional_services/${serviceId}`;
const enquiryRequestAuditPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/request_audit`;
const enquiryRequestModerationPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/request_moderation`;
const enquiryCompleteAuditPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/complete_audit`;
const enquiryCompleteModerationPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/complete_moderation`;
const enquiryPartFileSwapPath = (enquiryId: number, partId: number) =>
  `${apiBase}/enquiries/${enquiryId}/parts/${partId}/swap_file`;
const enquiryConfirmDraft = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/sa_confirm`;
const enquiryReissueProductionPartsPath = (enquiryId: number) =>
  `${apiBase}/enquiries/${enquiryId}/reissue_production_parts`;
const enquiryCalcFinishedPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/calc_finished`;
const enquiryReturnToCalcPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/recalculate`;
const enquirySkipCalculationPath = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/skip_calculation`;
const enquiryForceResetParts = (enquiryId: number) => `${apiBase}/enquiries/${enquiryId}/force_reset_parts`;
const dealEnquiryBulkRfqTemplatesPath = (_dealId: TypeRouteId, enquiryId: TypeRouteId): string =>
  `${enquiryPath(enquiryId)}/bulk_rfq_templates`;
const dealEnquiryBulkRfqSendPath = (_dealId: TypeRouteId, enquiryId: TypeRouteId): string =>
  `${enquiryPath(enquiryId)}/bulk_send_rfq`;
const dealEnquiryBulkRfqSendDirectPath = (_dealId: TypeRouteId, enquiryId: TypeRouteId): string =>
  `${enquiryPath(enquiryId)}/bulk_send_direct_rfq`;

const rfqPublicationsBulkRfqTemplatesPath = (): string => `${apiBase}/rfq_publications/bulk_rfq_templates`;
const rfqPublicationSend = (publicationId: string): string => `${apiBase}/rfq_publications/${publicationId}/bulk_send`;

const claimPath = (claimId: TypeRouteId) => `${apiBase}/claims/${claimId}`;
const claimEmailTemplatesPath = (claimId: TypeRouteId) => `${claimPath(claimId)}/email_templates`;
const claimSendEmailPath = (claimId: TypeRouteId) => `${claimPath(claimId)}/send_email`;
const claimSendDirectEmailPath = (claimId: TypeRouteId) => `${claimPath(claimId)}/send_direct_email`;

const dealOutcomingInvoicesPath = (dealId: string) => `${dealPath(dealId)}/outcoming_invoices`;

const incomingInvoicesPath = () => `${apiBase}/incoming_invoices`;
const incomingInvoicesValidateNumberPath = () => `${incomingInvoicesPath()}/validate_number`;

const incomingInvoicePath = (id: TypeRouteId) => `${incomingInvoicesPath()}/${id}`;
const incomingInvoiceEmailTemplatesPath = (id: TypeRouteId) => `${incomingInvoicePath(id)}/email_templates`;
const incomingInvoiceSendEmailPath = (id: TypeRouteId) => `${incomingInvoicePath(id)}/send_email`;
const incomingInvoiceSendDirectEmailPath = (id: TypeRouteId) => `${incomingInvoicePath(id)}/send_direct_email`;

const incomingInvoiceCombinedPdfPath = (id: string) => `${incomingInvoicePath(id)}/combined_pdf`;

const dealJobsPath = (dealId: string) => `${dealPath(dealId)}/jobs`;
const dealJobPath = (dealId: string, id: string) => `${dealPath(dealId)}/jobs/${id}`;

const dealJobOffersPath = (dealId: string, jobId: string) => `${dealJobPath(dealId, jobId)}/offers`;
const dealJobOfferPath = (dealId: string, jobId: string, id: string) => `${dealJobPath(dealId, jobId)}/offers/${id}`;
const dealJobOfferRejectPath = (dealId: string, jobId: string, id: string) =>
  `${dealJobOfferPath(dealId, jobId, id)}/reject`;
const dealJobOfferAcceptPath = (dealId: string, jobId: string, id: string) =>
  `${dealJobOfferPath(dealId, jobId, id)}/accept`;
const dealJobOfferEmailTemplatesPath = (dealId: string, jobId: string, id: string): string =>
  `${dealJobOfferPath(dealId, jobId, id)}/email_templates`;
const dealJobOfferEmailSendPath = (dealId: string, jobId: string, id: string): string =>
  `${dealJobOfferPath(dealId, jobId, id)}/send_email`;
const dealJobOfferSendDirectEmailPath = (dealId: string, jobId: string, id: string): string =>
  `${dealJobOfferPath(dealId, jobId, id)}/send_direct_email`;
const dealJobOfferUploadFilePath = (dealId: string, jobId: string, id: string): string =>
  `${dealJobOfferPath(dealId, jobId, id)}/upload_attached_files`;
const dealJobUploadFilePath = (dealId: string, jobId: string): string =>
  `${dealJobPath(dealId, jobId)}/upload_attached_files`;
const dealJobOfferBulkEmailTemplatesPath = (dealId: string, jobId: string): string =>
  `${dealJobPath(dealId, jobId)}/offers/bulk_email_templates`;
const dealJobOfferBulkEmailSendPath = (dealId: string, jobId: string): string =>
  `${dealJobPath(dealId, jobId)}/offers/bulk_send_email`;

const dealPaymentsPath = (dealId: TypeRouteId) => `${dealPath(dealId)}/payments`;
const dealPaymentPath = (dealId: TypeRouteId, paymentId: TypeRouteId) => `${dealPath(dealId)}/payments/${paymentId}`;
const paymentsPath = () => `${apiBase}/payments`;
const paymentPath = (id: TypeRouteId) => `${apiBase}/payments/${id}`;
const paymentStripeRefundPath = (id: TypeRouteId) => `${apiBase}/payments/${id}/refund_stripe`;
const paymentPaypalRefundPath = (id: TypeRouteId) => `${apiBase}/payments/${id}/refund_paypal`;
const paymentBankRefundPath = (id: TypeRouteId) => `${apiBase}/payments/${id}/refund_bank`;

type EntityForAttachment =
  | 'shippings'
  | 'master_shippings'
  | 'document_notes'
  | 'payouts'
  | 'claims'
  | 'outcoming_invoices'
  | 'payments'
  | 'order_confirmations'
  | 'storages'
  | 'incoming_invoices'
  | 'enquiries'
  | 'deals'
  | 'providers'
  | 'provider_orders'
  | 'quality_controls';

const entityForAttachments = (entity: EntityForAttachment, id: TypeRouteId) => `${apiBase}/${entity}/${id}`;
const entityAttachments = (entity: EntityForAttachment, id: TypeRouteId) =>
  `${entityForAttachments(entity, id)}/attached_files`;
const entityAttachmentsUploadPath = (entity: EntityForAttachment, id: TypeRouteId) =>
  `${entityForAttachments(entity, id)}/upload_attached_files`;
const entityAttachmentsDestroyPath = (entity: EntityForAttachment, id: TypeRouteId) =>
  `${entityForAttachments(entity, id)}/destroy_attached_file`;

const jobStrategiesPath = () => `${apiBase}/job/strategies`;
const providersSearchPath = () => `${apiBase}/providers/search`;
const providerInfoPath = (providerId: TypeRouteId) => `${apiBase}/providers/${providerId}/info`;

const partAttachmentsPath = (partId: string) => `${apiBase}/parts/${partId}/attachments`;
const partDrawingsPath = (partId: TypeRouteId) => `${apiBase}/parts/${partId}/drawings`;
const partNonDrawingsPath = (partId: TypeRouteId) => `${apiBase}/parts/${partId}/non_drawings`;
const partsClonePath = () => `${apiBase}/parts/clone`;
const partsCreateLotPath = (partId: TypeRouteId) => `${apiBase}/parts/${partId}/create_lot`;
const partCustomerLotsPath = (partId: TypeRouteId) => `${apiBase}/parts/${partId}/customer_lots`;

const personsPath = () => `${apiBase}/persons`;
const personPath = (id: TypeRouteId) => `${personsPath()}/${id}`;

const autocompletes = () => `${apiBase}/autocompletes`;
const autocompleteBAs = () => `${autocompletes()}/billing_accounts`;
const autocompleteOrgs = () => `${autocompletes()}/orgs`;
const autocompleteProviderOrders = () => `${autocompletes()}/provider_orders`;
const autocompletePersons = () => `${autocompletes()}/persons`;
const autocompletePersonsForBa = () => `${autocompletes()}/persons_for_ba`;
const autocompleteUsers = () => `${autocompletes()}/users`;
const autocompletePMs = () => `${autocompletes()}/pms`;
const autocompleteDeals = () => `${autocompletes()}/deals`;
const autocompleteProviders = () => `${autocompletes()}/providers`;
const autocompletePipedriveStages = () => `${autocompletes()}/pipedrive_stages`;
const autocompleteUsersPipedrive = () => `${autocompletes()}/users_pipedrive`;
const autocompleteAllTags = () => `${autocompletes()}/all_tags`;
const autocompleteTags = () => `${autocompletes()}/tags`;
const autocompletePreQuotedByProviders = () => `${autocompletes()}/pre_quoted_by_providers`;

const supportTicketsPath = () => `${apiBase}/support/tickets`;

const dealOrderConfirmation = (dealId: TypeRouteId) => `${apiBase}/deals/${dealId}/order_confirmation`;
const orderConfirmationPositionsPath = (dealId: TypeRouteId): string => `${dealOrderConfirmation(dealId)}/positions`;
const orderConfirmationCommentPath = (dealId: TypeRouteId): string =>
  `${dealOrderConfirmation(dealId)}/generate_comment`;
const orderConfirmationUpdateCurrencyPath = (dealId: TypeRouteId): string =>
  `${dealOrderConfirmation(dealId)}/update_currency`;
const orderConfirmationsPath = (id: TypeRouteId): string => `${apiBase}/order_confirmations/${id}`;

const dealOrderConfirmationLotsForJob = (dealId: TypeRouteId) =>
  `${apiBase}/deals/${dealId}/order_confirmation/lots_for_job`;

/* DELIVERIES */
const dealDeliveryAttachedFilesPath = (dealId: TypeRouteId, deliveryId: TypeRouteId) =>
  `${apiBase}/deals/${dealId}/deliveries/${deliveryId}/attached_files`;

/* SHIPPINGS */

const dealShippingsPath = (dealId: TypeRouteId) => `${apiBase}/deals/${dealId}/shippings`;
const dealShippingPath = (dealId: TypeRouteId, shippingId: TypeRouteId) => `${dealShippingsPath(dealId)}/${shippingId}`;
const dealShippingCancelPath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/cancel`;
const dealShippingClonePath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/clone`;
const dealShippingCreateReturnToXometryPath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/create_return_to_xometry;`;
const dealShippingReceiveForQCPath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/receive_for_qc`;
const dealShippingReceiveForStoragePath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/receive_for_storage`;
const dealShippingRepackForCustomerPath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/repack_for_customer`;
const dealShippingRepackForPartnerReturnPath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/repack_for_partner_return`;

const dealShippingUploadAttachedFilesPath = (dealId: TypeRouteId, shippingId: TypeRouteId) =>
  `${dealShippingPath(dealId, shippingId)}/upload_attached_files`;

const shippingsPath = () => `${apiBase}/shippings`;

interface IExtendedArgs {
  dealId: number;
  sourceType: string;
  sourceId: number;
}

const shippingsDefaultsPath = ({ dealId, sourceType, sourceId }: IExtendedArgs) =>
  `${shippingsPath()}/defaults?deal_id=${dealId}&source_type=${sourceType}&source_id=${sourceId}`;

const shippingsSourceAddressesPath = (dealId: number, sourceType: string, sourceId: number) =>
  `${shippingsPath()}/source_addresses?deal_id=${dealId}&source_type=${sourceType}&source_id=${sourceId}`;
const shippingsSourcesPath = (dealId: TypeRouteId, customerObjects: boolean = false) =>
  `${shippingsPath()}/sources?deal_id=${dealId}&customer_objects=${customerObjects}`;
const shippingsSourcePositionsPath = (dealId: TypeRouteId, sourceType: TypeRouteId, sourceId: TypeRouteId) =>
  `${shippingsPath()}/source_positions?deal_id=${dealId}&source_type=${sourceType}&source_id=${sourceId}`;
const shippingPath = (shippingId: TypeRouteId) => `${apiBase}/shippings/${shippingId}`;
const shippingEmailTemplatesPath = (shippingId: TypeRouteId) => `${shippingPath(shippingId)}/email_templates`;
const shippingSendEmailPath = (shippingId: TypeRouteId) => `${shippingPath(shippingId)}/send_email`;
const shippingSendDirectEmailPath = (shippingId: TypeRouteId) => `${shippingPath(shippingId)}/send_direct_email`;
const shippingDownloadAllLabelsPath = (shippingId: TypeRouteId) => `${shippingPath(shippingId)}/download_all_labels`;
const shippingDownloadDeliveryNotePath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_delivery_note`;
const shippingDownloadCommercialInvoicePath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_commercial_invoice`;
const shippingDownloadInventoryNotePath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_inventory_note`;
const shippingDownloadPickingListPath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_picking_list`;
const shippingDownloadReferenceTagPath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_reference_tag`;
const shippingDownloadShippingDescriptionPath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_shipping_description`;
const shippingDownloadTechDetailsPath = (shippingId: TypeRouteId) =>
  `${shippingPath(shippingId)}/download_tech_details`;
const shippingDownloadDhlCsvPath = (shippingId: TypeRouteId) => `${shippingPath(shippingId)}/download_dhl_csv`;
const shippingsBulkDocumentsPath = () => `${shippingsPath()}/bulk_documents`;
const shippingsBulkShippingDescriptionPath = () => `${shippingsBulkDocumentsPath()}/download_shipping_description`;
const shippingsBulkCommercialInvoicePath = () => `${shippingsBulkDocumentsPath()}/download_commercial_invoice`;
const shippingsBulkTechDetailsPath = () => `${shippingsBulkDocumentsPath()}/download_tech_details`;

const _masterShippingPath = (id: TypeRouteId) => `${apiBase}/master_shippings/${id}`;
const masterShippingDownloadAllLabelsPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/download_all_labels`;
const masterShippingUploadAttachedFilesPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/upload_attached_files`;
const masterShippingReferenceTagPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/download_reference_tag`;
const masterShippingInventoryNotePath = (id: TypeRouteId) => `${_masterShippingPath(id)}/download_inventory_note`;
const masterShippingDownloadDeliveryNotePath = (id: TypeRouteId) => `${_masterShippingPath(id)}/download_delivery_note`;
const masterShippingDownloadShippingDescriptionPath = (id: TypeRouteId) =>
  `${_masterShippingPath(id)}/download_shipping_description`;
const masterShippingDownloadTechDetailsPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/download_tech_details`;
const masterShippingDownloadCommercialInvoicePath = (id: TypeRouteId) =>
  `${_masterShippingPath(id)}/download_commercial_invoice`;
const masterShippingDownloadDhlCsvPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/download_dhl_csv`;
const masterShippingEmailTemplatesPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/email_templates`;
const masterShippingSendEmailPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/send_email`;
const masterShippingSendDirectEmailPath = (id: TypeRouteId) => `${_masterShippingPath(id)}/send_direct_email`;

/* STORAGES */
const dealStoragesPath = (dealId: TypeRouteId) => `${apiBase}/deals/${dealId}/storages`;
const dealStoragePath = (dealId: TypeRouteId, storageId: TypeRouteId) => `${dealStoragesPath(dealId)}/${storageId}`;
const dealStorageRepackPath = (dealId: TypeRouteId, storageId: TypeRouteId) =>
  `${dealStoragePath(dealId, storageId)}/repack`;

const storageSourcesPath = (dealId: number) => `${apiBase}/storages/sources?deal_id=${dealId}`;
const storageSourcePositionsPath = (dealId: TypeRouteId, sourceType: string, sourceId: TypeRouteId) =>
  `${apiBase}/storages/source_positions?deal_id=${dealId}&source_type=${sourceType}&source_id=${sourceId}`;

const storagePath = (storageId: TypeRouteId) => `${apiBase}/storages/${storageId}`;
const storageInventoryNotePath = (storageId: TypeRouteId) => `${storagePath(storageId)}/inventory_note`;
const downloadStorageInventoryNotePath = (storageId: TypeRouteId) =>
  `${storagePath(storageId)}/download_inventory_note`;
const storageActivityRecordsPath = (storageId: TypeRouteId) => `${storagePath(storageId)}/activity_records`;
const createQCFromStoragePath = (storageId: TypeRouteId) => `${storagePath(storageId)}/quality_controls`;
const createShippingFromStoragePath = (storageId: TypeRouteId) => `${storagePath(storageId)}/shippings`;

const dealProviderOrdersPath = (dealId: string | number) => `${apiBase}/deals/${dealId}/provider_orders`;
const dealProviderOrderPath = (dealId: TypeRouteId, id: TypeRouteId) => `${dealProviderOrdersPath(dealId)}/${id}`;
const dealProviderOrdersPositionsPath = (dealId: TypeRouteId, providerOrderId: TypeRouteId) =>
  `${dealProviderOrderPath(dealId, providerOrderId)}/positions`;
const dealProviderOrderSplitPath = (dealId: TypeRouteId, id: TypeRouteId) =>
  `${dealProviderOrderPath(dealId, id)}/split`;

const dealProviderOrderEmailTemplatesPath = (dealId: TypeRouteId, id: TypeRouteId): string =>
  `${dealProviderOrderPath(dealId, id)}/email_templates`;
const dealProviderOrderEmailSendPath = (dealId: TypeRouteId, id: TypeRouteId): string =>
  `${dealProviderOrderPath(dealId, id)}/send_email`;
const dealProviderOrderSendDirectEmailPath = (dealId: TypeRouteId, id: TypeRouteId): string =>
  `${dealProviderOrderPath(dealId, id)}/send_direct_email`;

const dealProviderOrdersSelectPath = (dealId: TypeRouteId) => `${apiBase}/deals/${dealId}/provider_orders/select`;

const providerOrderPositionsPath = (id: TypeRouteId) => `${apiBase}/provider_orders/${id}/positions`;
const providerOrderExportPath = (id: TypeRouteId, type: 'download_po_pdf' | 'download_td_pdf'): string =>
  `${apiBase}/provider_orders/${id}/${type}`;

const dealQualityControlPath = (dealId: TypeRouteId, qcId: TypeRouteId) =>
  `${apiBase}/deals/${dealId}/quality_controls/${qcId}`;

const dealQualityControlPositionPath = (dealId: TypeRouteId, qcId: TypeRouteId, positionId: TypeRouteId) =>
  `${dealQualityControlPath(dealId, qcId)}/positions/${positionId}`;
const dealQualityControlPositionAttachedFilesPath = (dealId: TypeRouteId, qcId: TypeRouteId, positionId: TypeRouteId) =>
  `${dealQualityControlPositionPath(dealId, qcId, positionId)}/attached_files`;
const dealQualityControlPositionUploadAttachedFilesPath = (
  dealId: TypeRouteId,
  qcId: TypeRouteId,
  positionId: TypeRouteId,
  options: Record<string, string>,
) =>
  `${dealQualityControlPositionPath(dealId, qcId, positionId)}/upload_attached_files?context=${options.context || ''}`;
const dealQualityControlPositionDestroyAttachedFilePath = (
  dealId: TypeRouteId,
  qcId: TypeRouteId,
  positionId: TypeRouteId,
) => `${dealQualityControlPositionPath(dealId, qcId, positionId)}/destroy_attached_file`;

const qualityControlPath = (qcId: TypeRouteId) => `${apiBase}/quality_controls/${qcId}`;
const qualityControlEmailTemplatesPath = (qcId: TypeRouteId) => `${qualityControlPath(qcId)}/email_templates`;
const qualityControlSendEmailPath = (qcId: TypeRouteId) => `${qualityControlPath(qcId)}/send_email`;
const qualityControlSendDirectEmailPath = (qcId: TypeRouteId) => `${qualityControlPath(qcId)}/send_direct_email`;

const attachedFilesPath = () => `${apiBase}/attached_files`;
const attachedFilePath = (id: TypeRouteId) => `${attachedFilesPath()}/${id}`;
const downloadFilesPath = () => `${apiEndpoint ?? ''}/download_files`;

const downloadRfqZipCleanedFiles = (id: TypeRouteId) => `${apiBase ?? ''}/rfqs/${id}/download_zip`;
const downloadRfqTemplate = (id: TypeRouteId) => `${apiBase ?? ''}/rfqs/${id}/download`;
const rfqOfferUploadFile = (id: TypeRouteId) => `${apiBase ?? ''}/rfq_offers/${id}/upload`;

const productionScheduleItemAttachedFilesPath = (productionScheduleItemId: TypeRouteId) =>
  `${apiBase}/production_schedule_items/${productionScheduleItemId}/attached_files`;

export const routesApi = {
  ssoLogin,
  authPath,
  versionsPath,

  asyncJobsPath,
  asyncJobPath,

  logoutAuthPath: () => `${authPath()}/logout`,
  refreshAuthPath: () => `${authPath()}/refresh`,
  supportTicketsPath,

  bankPaymentsSendToJpmPath,
  uploadBulkIncomingInvoices,
  uploadBulkOutcomingInvoices,

  dealPath,
  dealsPath,

  dealQualityControlPositionAttachedFilesPath,
  dealQualityControlPositionUploadAttachedFilesPath,
  dealQualityControlPositionDestroyAttachedFilePath,

  qualityControlEmailTemplatesPath,
  qualityControlSendEmailPath,
  qualityControlSendDirectEmailPath,

  attachedFilesPath,
  attachedFilesInfoPath: (uuidIds: string[]) => {
    const query = uuidIds.map((i) => `uuid_ids[]=${i}`).join('&');

    return `${attachedFilesPath()}?${query}`;
  },
  attachedFilePath,
  attachedFileLinkPath: (id: TypeRouteId) => `${attachedFilePath(id)}/link`,
  attachedFilePreviewPath: (id: TypeRouteId) => `${attachedFilePath(id)}/preview`,
  attachedFileSkipCleaningPath: (id: TypeRouteId) => `${attachedFilePath(id)}/skip_cleaning`,
  attachedFileDownloadPath: (id: TypeRouteId) => `${attachedFilePath(id)}/download`,
  groupFileDownload: (uuidIds: string[], filename?: string) => {
    let query = uuidIds.map((i) => `uuid_ids[]=${i}`).join('&');

    if (filename) {
      query = `filename=${filename}&${query}`;
    }

    return `${downloadFilesPath()}?${query}`;
  },

  lookerPath: (urlFor: string) => `${apiBase}/lookers/get_by_url?url=${encodeURIComponent(urlFor)}`,

  billingAccountsPath: () => `${apiBase}/billing_accounts`,
  billingAccountPath: (id: TypeRouteId) => `${apiBase}/billing_accounts/${id}`,
  billingAccountValidateIntacctPath: () => `${apiBase}/billing_accounts/validate_intacct_id`,
  billingAccountValidateDATEVPath: () => `${apiBase}/billing_accounts/validate_datev_id`,
  billingAccountEmailTemplatesPath: (id: TypeRouteId) => `${apiBase}/billing_accounts/${id}/email_templates`,
  billingAccountSendEmail: (id: TypeRouteId) => `${apiBase}/billing_accounts/${id}/send_email`,
  billingAccountSendDirectEmail: (id: TypeRouteId) => `${apiBase}/billing_accounts/${id}/send_direct_email`,

  shippingAddressesPath: () => `${apiBase}/shipping_addresses`,
  shippingAddressPath: (id: TypeRouteId) => `${apiBase}/shipping_addresses/${id}`,

  masterShippingDownloadAllLabelsPath,
  masterShippingUploadAttachedFilesPath,
  masterShippingReferenceTagPath,
  masterShippingInventoryNotePath,
  masterShippingDownloadDeliveryNotePath,
  masterShippingDownloadShippingDescriptionPath,
  masterShippingDownloadTechDetailsPath,
  masterShippingDownloadCommercialInvoicePath,
  masterShippingDownloadDhlCsvPath,
  masterShippingEmailTemplatesPath,
  masterShippingSendEmailPath,
  masterShippingSendDirectEmailPath,

  // ACCOUNTING
  accountingExportOutcomingsPath: () => `${apiBase}/${accountingIdName}/exports/outcomings`,
  accountingExportOutcomingPath: (id: TypeRouteId) => `${apiBase}/${accountingIdName}/exports/outcomings/${id}`,
  accountingExportOutcomingsSearchPath: () => `${apiBase}/${accountingIdName}/exports/outcomings/search`,

  accountingExportGenerateCSVPath: (id: TypeRouteId) =>
    `${apiBase}/${accountingIdName}/exports/outcomings/${id}/report_csv`,
  accountingRecordsInvoiceExportGenerateCSVPath: (id: TypeRouteId) =>
    `${apiBase}/${accountingIdName}/exports/outcomings/${id}/report_accounting_records_invoice_csv`,
  accountingRecordsAllocationExportGenerateCSVPath: (id: TypeRouteId) =>
    `${apiBase}/${accountingIdName}/exports/outcomings/${id}/report_accounting_records_allocation_csv`,

  accountingExportIncomingsPath: () => `${apiBase}/${accountingIdName}/exports/incomings`,
  accountingExportIncomingPath: (id: TypeRouteId) => `${apiBase}/${accountingIdName}/exports/incomings/${id}`,
  accountingExportIncomingsSearchPath: () => `${apiBase}/${accountingIdName}/exports/incomings/search`,

  dealEmailTemplates,
  dealSendEmail,
  dealSendDirectEmail,

  templatePreview,

  dealProgressEmailTemplatesPath,

  dealFetchUnlinkedFilesPath,
  dealFetchFiles,
  dealFetchPipedriveFiles,
  dealUploadPdFiles,
  dealUploadUserFiles,
  dealDestroyFiles,
  dealUploadFile,
  dealRedirectUrl,
  dealEnquiryPath,

  providerEmailTemplates,
  providerRatingInfoPdf,
  providerSendEmail,
  providerSendDirectEmail,

  eventTypesPath: () => `${apiBase}/event_types`,

  eventsPath: () => `${apiBase}/events`,
  eventPath: (eventId: TypeRouteId) => `${apiBase}/events/${eventId}`,
  eventClonePath: (eventId: TypeRouteId) => `${apiBase}/events/${eventId}/clone`,

  revisionsPath: () => `${apiBase}/revisions`,
  revisionsByDealPath: () => `${apiBase}/revisions/by_deal`,

  dealOutcomingInvoicesPath,
  outcomingInvoicesUploadMicroJumpDocumentPath,

  enquiryPath,
  enquiriesPath,
  enquiryPartsPath,
  enquiryPartsFileUploadPath,
  enquiryRestorePartPath,
  enquiryStatePath,
  enquiryDownloadRFQMoldingPath,
  enquiryPdfPath,
  enquiryRfqPath,
  enquiryCloneQuote,
  enquiryCancelPath,
  enquiryRecalculateSummaryPath,
  enquiryBulkCloneParts,
  enquiryBulkUpdateStandard,
  enquiryBulkUpdateExpress,
  enquiryBulkUpdateFeatures,
  enquiryBulkUpdateRfq,
  enquiryBulkUpdatePreQuote,
  enquiryBulkUpdateTags,
  enquiryBulkUpdateManualPriceExpiration,
  enquiryBulkUpdateIQEPrice,
  enquiryPartUpdateManualPriceExpiration,
  enquiriesEmailTemplatesPath: (id: TypeRouteId): string => `${enquiryPath(id)}/email_templates`,
  enquiriesEmailSendPath: (id: TypeRouteId): string => `${enquiryPath(id)}/send_email`,
  enquiriesEmailSendDirectPath: (id: TypeRouteId): string => `${enquiryPath(id)}/send_direct_email`,
  enquiryCheckPromotionPath,
  enquiryUpdatePropmotions,
  enquiryUpdateCurrency,
  enquiryFeaturesPath,
  enquiryMaterialsPath,
  enquiryPartAttachmentsPath,
  enquiryCreateAdditionalServicePath,
  enquiryUpdateVatPath,
  enquiryUpdateAdditionalServicePath,
  enquiryRequestAuditPath,
  enquiryRequestModerationPath,
  enquiryCompleteAuditPath,
  enquiryCompleteModerationPath,
  enquiryPartFileSwapPath,
  enquiryConfirmDraft,
  enquiryReissueProductionPartsPath,
  enquiryCalcFinishedPath,
  enquiryReturnToCalcPath,
  enquirySkipCalculationPath,
  enquiryForceResetParts,
  dealEnquiryBulkRfqTemplatesPath,
  dealEnquiryBulkRfqSendPath,
  rfqPublicationsBulkRfqTemplatesPath,
  rfqPublicationSend,
  dealEnquiryBulkRfqSendDirectPath,

  incomingInvoiceCombinedPdfPath,

  claimEmailTemplatesPath,
  claimSendEmailPath,
  claimSendDirectEmailPath,

  dealJobsPath,
  dealJobPath,

  dealPartsPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts`,
  dealPartsBulkCreatePath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts/bulk_create`,
  dealPartsBulkUpdatePath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts/bulk_update`,
  dealPartsBulkRegeneratePreviewPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts/bulk_regenerate_preview`,
  dealPartsZipOriginalPath: (dealId: number) => `${dealPath(dealId)}/parts/zip_original_files`,
  dealPartsZipCleanedPath: (dealId: number) => `${dealPath(dealId)}/parts/zip_cleaned_files`,

  customerObjectsPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts/customer_objects`,
  customerObjectPath: (dealId: TypeRouteId, customerObjectId: TypeRouteId) =>
    `${dealPath(dealId)}/parts/customer_objects/${customerObjectId}`,
  customerObjectsBulkCreatePath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts/customer_objects/bulk_create`,

  partDrawingsPath,
  partNonDrawingsPath,
  partAttachmentsPath,
  partsClonePath,

  partPath: (partId: TypeRouteId) => `${apiBase}/parts/${partId}`,
  partsCreateLotPath,
  partCustomerLotsPath,

  dealJobOffersPath,
  dealJobOfferPath,
  dealJobOfferRejectPath,
  dealJobOfferAcceptPath,
  dealJobOfferEmailTemplatesPath,
  dealJobOfferEmailSendPath,
  dealJobOfferSendDirectEmailPath,
  dealJobOfferUploadFilePath,
  dealJobUploadFilePath,
  dealJobOfferBulkEmailTemplatesPath,
  dealJobOfferBulkEmailSendPath,

  jobStrategiesPath,
  providersSearchPath,
  providerInfoPath,

  activityRecordsPath: (objectType: string, objectId: TypeRouteId) =>
    `${apiBase}/activity_records?object_type=${objectType}&object_id=${objectId}`,

  dealOutcomingInvoicePath: (dealId: string, invoiceId: string) => `${dealOutcomingInvoicesPath(dealId)}/${invoiceId}`,

  createIncomingInvoicePath: () => incomingInvoicesPath(),

  incomingInvoicesPath,
  incomingInvoiceEmailTemplatesPath,
  incomingInvoiceSendEmailPath,
  incomingInvoiceSendDirectEmailPath,
  incomingInvoicesValidateNumberPath,

  changeStateIncomingInvoicePath: (invoiceId: TypeRouteId) => `${incomingInvoicePath(invoiceId)}/change_state`,

  incomingInvoicePath,

  dealInvoicePositionsSourcesPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/invoice_positions/sources`,

  dealInvoicePositionsSourcedPath: (dealId: string) => `${dealPath(dealId)}/invoice_positions/sourced`,

  outcomingInvoicesPositionsPath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/invoice_positions`,

  outcomingInvoiceEmailTemplatesPath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/email_templates`,

  outcomingInvoiceSendEmailPath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/send_email`,
  outcomingInvoiceSendDirectEmailPath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/send_direct_email`,

  outcomingInvoiceExportPath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/export.pdf`,
  outcomingInvoicePreviewPath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/preview`,

  publishOutcomingInvoicePath: (invoiceId: string) => `${outcomingInvoicePath(invoiceId)}/publish`,

  // PAYMENTS START
  dealPaymentsPath,
  dealPaymentPath,
  dealPaymentSourcesPath: (dealId: TypeRouteId) => `${dealPaymentsPath(dealId)}/sources`,
  dealPaymentSourcesPosPath: (dealId: TypeRouteId) => `${dealPaymentsPath(dealId)}/source_positions`,

  paymentsPath,
  paymentPath,
  paymentEmailTemplatesPath: (id: TypeRouteId) => `${paymentPath(id)}/email_templates`,
  paymentSendEmailPath: (id: TypeRouteId) => `${paymentPath(id)}/send_email`,
  paymentSendDirectEmailPath: (id: TypeRouteId) => `${paymentPath(id)}/send_direct_email`,
  paymentCancelPath: (id: TypeRouteId) => `${paymentPath(id)}/cancel`,
  paymentSucceedPath: (id: TypeRouteId) => `${paymentPath(id)}/succeed`,
  paymentProformaPdfPath: (id: TypeRouteId) => `${paymentPath(id)}/proforma_pdf`,
  paymentConfirmationPdfPath: (id: TypeRouteId) => `${paymentPath(id)}/confirmation_pdf`,
  paymentStripeRefundPath,
  paymentPaypalRefundPath,
  paymentBankRefundPath,
  // PAYMENTS END

  entityAttachments,
  entityAttachmentsUploadPath,
  entityAttachmentsDestroyPath,

  personPath,
  personCreateContactPath: (id: TypeRouteId) => `${personPath(id)}/create_contact`,
  personResetPasswordPath: (id: TypeRouteId) => `${personPath(id)}/reset_password`,
  personVerifyPath: (id: TypeRouteId) => `${personPath(id)}/verify`,
  personsPath,

  orgPath,
  orgsPath,

  personImpersonatePath: (id: TypeRouteId) => `${personPath(id)}/impersonate`,
  smartSearchPath: (value: string) => `${apiBase}/smart_search?search=${value}`,

  providerContactImpersonatePath: (providerId: TypeRouteId, id: TypeRouteId) =>
    `${apiBase}/providers/${providerId}/emulation_link?contact_id=${id}`,

  autocompleteBAs,
  autocompleteUsers,
  autocompleteProviderOrders,
  autocompletePMs,
  autocompleteDeals,
  autocompleteProviders,
  autocompletePipedriveStages,
  autocompleteOrgs,
  autocompletePersons,
  autocompletePersonsForBa,
  autocompleteAllTags,
  autocompleteTags,
  autocompletePreQuotedByProviders,
  autocompleteUsersPipedrive,

  dealOrderConfirmation,
  orderConfirmationPositionsPath,
  orderConfirmationCommentPath,
  orderConfirmationUpdateCurrencyPath,
  orderConfirmationsPath,
  orderConfirmationsPdfPath: (id: number): string => `${orderConfirmationsPath(id)}/pdf`,
  orderConfirmationsExportPath: (id: number): string => `${orderConfirmationsPath(id)}/export`,
  orderConfirmationsEmailTemplatesPath: (id: number): string => `${orderConfirmationsPath(id)}/email_templates`,
  orderConfirmationsEmailSendPath: (id: number): string => `${orderConfirmationsPath(id)}/send_email`,
  orderConfirmationsSendDirectEmailPath: (id: number): string => `${orderConfirmationsPath(id)}/send_direct_email`,
  orderConfirmationsDownloadCertificate21Path: (id: number): string =>
    `${orderConfirmationsPath(id)}/download_certificate_2_1`,
  orderConfirmationsDownloadCertificate22Path: (id: number): string =>
    `${orderConfirmationsPath(id)}/download_certificate_2_2`,
  dealOrderConfirmationLotsForJob,

  dealDeliveryAttachedFilesPath,

  dealShippingsPath,
  dealShippingPath,
  dealShippingCancelPath,
  dealShippingClonePath,
  dealShippingCreateReturnToXometryPath,
  dealShippingReceiveForQCPath,
  dealShippingReceiveForStoragePath,
  dealShippingRepackForCustomerPath,
  dealShippingRepackForPartnerReturnPath,
  dealShippingUploadAttachedFilesPath,

  shippingsSourcesPath,
  shippingsDefaultsPath,
  shippingsSourceAddressesPath,
  shippingsSourcePositionsPath,
  shippingEmailTemplatesPath,
  shippingSendEmailPath,
  shippingSendDirectEmailPath,
  shippingDownloadAllLabelsPath,
  shippingDownloadDeliveryNotePath,
  shippingDownloadCommercialInvoicePath,
  shippingDownloadInventoryNotePath,
  shippingDownloadPickingListPath,
  shippingDownloadReferenceTagPath,
  shippingDownloadShippingDescriptionPath,
  shippingDownloadTechDetailsPath,
  shippingDownloadDhlCsvPath,
  shippingsBulkShippingDescriptionPath,
  shippingsBulkCommercialInvoicePath,
  shippingsBulkTechDetailsPath,

  dealProviderOrdersPath,
  dealProviderOrderPath,
  dealProviderOrderSplitPath,
  dealProviderOrdersPositionsPath,
  dealProviderOrderEmailTemplatesPath,
  dealProviderOrderEmailSendPath,
  dealProviderOrderSendDirectEmailPath,

  dealProviderOrdersSelectPath,

  providerOrderPositionsPath,
  providerOrderExportPath,

  dealStoragesPath,
  dealStoragePath,
  dealStorageRepackPath,
  storageSourcesPath,
  storageSourcePositionsPath,
  storageInventoryNotePath,
  downloadStorageInventoryNotePath,
  storageActivityRecordsPath,
  createQCFromStoragePath,
  createShippingFromStoragePath,
  downloadRfqZipCleanedFiles,
  downloadRfqTemplate,
  rfqOfferUploadFile,

  productionScheduleItemAttachedFilesPath,
};
