import { WorkspacesRfqOffer } from '__generated__/types';
import documentUrl from 'components/utils/documentUrl';
import { ObjectType } from 'interfaces';
import React from 'react';
import { Link } from 'react-router-dom';

export const rfqIdDecorator = (rfqOffer: WorkspacesRfqOffer) => (
  <Link to={documentUrl(ObjectType.RFQ, rfqOffer.dealId, rfqOffer.rfqId)} target="_blank" rel="noreferrer noopener">
    <b>{rfqOffer.rfqNumber}</b>
  </Link>
);
