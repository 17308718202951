import { ProviderStateEnum } from '__generated__/types';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { PayoutTransferTypeCol } from 'interfaces/graphql/workspaces';

import { countries as baseCountries } from './countries';

export const countries = baseCountries.map((c) => ({
  label: c.name,
  value: c.name,
}));

/**
 * @deprecated use statesOpts instead
 */
export const states = [
  { value: 'found_by_us', label: 'Found by us' },
  { value: 'enrolled_via_website', label: 'Enrolled via website' },
  { value: 'probation', label: 'Probation' },
  { value: 'test_part', label: 'Test part' },
  { value: 'trusted', label: 'Trusted' },
  { value: 'blacklist', label: 'Blacklist' },
  { value: 'deleted', label: 'Deleted' },
];

export const statesOpts: { value: ProviderStateEnum; label: string }[] = [
  { value: ProviderStateEnum.FoundByUs, label: 'Found by us' },
  { value: ProviderStateEnum.EnrolledViaWebsite, label: 'Enrolled via website' },
  { value: ProviderStateEnum.Probation, label: 'Probation' },
  { value: ProviderStateEnum.TestPart, label: 'Test part' },
  { value: ProviderStateEnum.Trusted, label: 'Trusted' },
  { value: ProviderStateEnum.Blacklist, label: 'Blacklist' },
  { value: ProviderStateEnum.Deleted, label: 'Deleted' },
];

export const providerStateTranslations = Object.fromEntries(
  statesOpts.map(({ label, value }) => [value, label]),
) as Record<ProviderStateEnum, string>;

export enum PayoutTransferTypeEnum {
  ach_bucs = 'ACH BUCS',
  ach_sepa = 'ACH SEPA',
  Wire = 'Wire',
}

const payoutTransferTypesByRegion: Record<Regions, { value: string; label: string }[]> = {
  [Regions.EU]: [
    { value: PayoutTransferTypeCol.ACH, label: 'ACH SEPA' },
    { value: PayoutTransferTypeCol.Wire, label: 'Wire' },
  ],
  [Regions.INDIA]: [{ value: PayoutTransferTypeCol.Wire, label: 'Wire' }],
  [Regions.TR]: [{ value: PayoutTransferTypeCol.Wire, label: 'Wire' }],
  [Regions.UK]: [
    { value: PayoutTransferTypeCol.ACHBuks, label: 'ACH BUCS' },
    { value: PayoutTransferTypeCol.Wire, label: 'Wire' },
  ],
};

export const payoutTransferTypes = payoutTransferTypesByRegion[CURRENT_REGION];
